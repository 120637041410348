import React from 'react';
import finis from '../../../../Images/Controller.png';
import batterydisplay from '../../../../Images/batteryStack.png';
import screenDisplay from '../../../../Images/Schermafbeelding 2023-08-16 164524slanted.png';

const Horeca = () => {
    return (
        <div className="sectoren__body">
            <h1 className='sectoren__header'>Horeca</h1>
            <section className="sectoren__section">
                <img src={finis} alt="Finis" className="sectoren__image" />
                <div className="sectoren__text">
                    <p>
                        Iedereen heeft vorig jaar de enorme extra druk gevoeld door de ENERGIEPRIJZEN in deze reeds zware HORECA sector.
                        Met de DAY AHEAD uurprijzen werkt U altijd aan de ALLERLAAGSTE ENERGIE UURPRIJZEN. Meestal is er in de HORECA sector veel meer verbruik dan met zonnepanelen kan gemaakt worden en verbruiksprofiel loopt meestal niet samen met wanneer er zon is <i className='fas fa-arrow-right' /> energiemanagement - aankoop op lage uren en energiestorage zorgen voor enorme besparingen!
                        DE 30% ECOLOGIESTEUN voor KMO helpt zeker bij deze investeringen.
                    </p>
                    <h2>FIRN energy aanbod</h2>
                    <ul>
                        <li>FIRN Energie Management Systeem</li>
                    </ul>
                    <p>
                        Werkt op de DAY AHEAD energie prijs per uur.
                        Automatische aankoop in de winter op de lage uurprijzen als er niet voldoende productie is van de zonnepanelen.
                        Bij overschot in de zomer energie verkopen aan dure uurprijzen i.p.v. bijna gratis weg te geven.
                    </p>
                    <p>
                        Grotere verbruikers sturen in functie van energieprijzen en eigen productie of energieopslag.
                        Verbruik en opslag sturen via AI voorspelde eigen verbruik/productie/uurprijzen.
                    </p>
                </div>
            </section>

            <section className="sectoren__section">
                <div className="sectoren__text sectoren__text--brown">
                    <h2>FIRN Energie Storage</h2>
                    <p>
                        De energie opslag is het buffervat om uw eigen verbruik te optimaliseren en optimaal van uw eigen energie gebruik te kunnen maken.
                        <br />
                        De veel grotere opslagcapaciteit dan standaard thuisbatterijen zorgt dat u een modulair en uitbreidbaar opslag systeem hebt aangepast aan de typisch veel grotere verbruikers op een landbouwbedrijf.
                    </p>
                    <h2>FIRN omvormers</h2>
                    <p>
                        Retrofit of hybride omvormers. Indien u al zonnepanelen hebt kunnen we naadloos alles integreren in uw bestaande installatie via een retrofit omvormer.
                        Met een hybride omvormer kan alles in 1 systeem.
                    </p>
                </div>
                <div className="sectoren__image">
                    <img src={batterydisplay} alt="Battery Display" />
                </div>
            </section>

            <section className="sectoren__section">
                <img src={screenDisplay} alt="Screen Display" className="sectoren__image" />
                <div className="sectoren__text">
                    <h2>FIRN laadpalen</h2>
                    <p>
                        De elektrische wagens worden in sneltempo de grootste energieverbruikers in de woning. De TellToCharge laadpalen integreren in het totaal systeem is een evidentie.
                    </p>
                    <h2>FIRN energiemetingen</h2>
                    <p>
                        Energie productie en verbruiken meten en visualiseren blijft altijd een basis van een energie management systeem.
                    </p>
                    <h2>FIRN visualisatie</h2>
                    <p>
                        Energie productie en verbruiken meten en visualiseren, besparingen in kaart brengen zijn mogelijk via een app en Website.
                    </p>
                </div>
            </section>
        </div>
    );
}

export default Horeca;

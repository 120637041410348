import Wave from 'react-wavify'
import BatteryChart from '../Charts/batteryChart'
import { useContext, useEffect, useState } from 'react';
import { DarkModeContext } from '../../../../context/DarkModeContext';
import ImbalanceChart from '../Charts/ImbalanceChart';

import loaderGIF from '../../../../Images/firn-embleem-animation.gif'


import './batteryStats.css'
import { addDays, isToday, subDays } from 'date-fns';

const BatteryStats = ({serialNr, batteryPercentage, batteryCapacity}) => {

    const [triggerDataRetrieval, setTriggerDataRetrieval] = useState(new Date())
    const [activity, setActivity] = useState("")
    const {darkMode} = useContext(DarkModeContext);
    const [items, setItems] = useState([
        {title: 'SOC & Planning'}
    ])
    const [activeItem, setActiveItem] = useState(0)

    const [date, setDate] = useState(new Date())

    const TriggerActivity = (activity) => {
        setActivity(activity)
    }

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();
      
        return `${day}-${month}-${year}`;
    }

    const batteryColors = [
        '#D83535',
        '#CD4935',
        '#C15E35',
        '#B67135',
        '#AB8635',
        '#A69135',
        '#A09B35',
        '#95AE35',
        '#89C435',
        '#00B26C' 
    ];

    const handleDateBackClick = () => {
        setDate(subDays(date, 1))
        setTriggerDataRetrieval(subDays(date, 1))
    }

    const handleDateForwardClick = () => {
        if (!isToday(date)) {
            setDate(addDays(date, 1));
            setTriggerDataRetrieval(addDays(date, 1))
        }
        
    }

    return (
        <>
            {batteryPercentage == 0 ?
                <img style={{margin: 'auto'}} height={150} width={150} className='loading-gif' src={loaderGIF} alt="" />
            :   
            <>
                {batteryPercentage == 1 ?
                    <>
                        <div>Error Rendering Battery stats contact customer service</div>
                    </>
                    :
                    <>
                        <div className='Dashboard-Body-Overview-Row-Element-Object-Container'>
                            <div className='Dashboard-Body-Overview-Row-Element-Battery-Container'>
                                
                                <div className='Dashboard-Body-Overview-Row-Element-Battery-Object-Bottom' style={{border: darkMode ?  'white solid 4px' : 'gray solid 4px'}}>
                                    <div className='dashboard__body__overview__row__element__battery__object__filler' style={{width: `${batteryPercentage}%`, backgroundColor: `${batteryColors[Math.ceil(batteryPercentage/10) - 1]}`}}></div>
                                </div>
                                <div className='Dashboard-Body-Overview-Row-Element-Battery-Object-Top' style={{border: darkMode ? 'white solid 4px' : 'gray solid 4px'}}></div>
                            </div>
                            
                            <div className='Dashboard-Body-Overview-Row-Element-Battery-Container Percentage'>
                                {/* <div style={{visibility: "hidden", border: darkMode ? 'white solid 4px' : 'black solid 4px'}} className='Dashboard-Body-Overview-Row-Element-Battery-Object-Top'></div> */}
                                <div className='Dashboard-Body-Overview-Row-Element-Battery-Object-Bottom Percentage'>
                                    <div style={{width: `${batteryPercentage - 10}%`}} className='Dashboard-Body-Overview-Row-Element-Battery-Object-Bottom-Indicator-Pushback'></div>
                                    <div className='Dashboard-Body-Overview-Row-Element-Battery-Object-Bottom-Indicator-Container'>
                                        <div className='Dashboard-Body-Overview-Row-Element-Battery-Object-Bottom-Indicator' style={{backgroundColor: darkMode ? 'white' : 'gray'}}></div>
                                        <div>{`${batteryPercentage}%`}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='Dashboard-Body-Overview-Row-Element-Battery-Data-Container'>
                            <div className='Dashboard-Body-Overview-Row-Element-Battery-Data-Graph-Container'>
                                <div className='dashboard__overview__Battery__box__graph__titles'>
                                    {items.map((item, i) => (
                                        <div key={i} className={`dashboard__overview__Battery__box__graph__titles__item${i === activeItem ? '--active' : ''}`} onClick={() => setActiveItem(i)}>{item.title}</div>
                                    ))}
                                    <div style={darkMode ? {backgroundColor: '#353535', border: '2px solid #606060'} : {backgroundColor: '#D0D0D0', border: '2px solid #A0A0A0'}} className='GraphDisplay-Date-Date-Container'>
                                        <div className='GraphDisplay-Date-Changer' onClick={handleDateBackClick}>
                                            <div style={darkMode ? {backgroundColor: '#D9D9D9'} : {backgroundColor: '#808080'}} className='GraphDisplay-Date-Changer-Bar Top Left'></div>
                                            <div style={darkMode ? {backgroundColor: '#D9D9D9'} : {backgroundColor: '#808080'}} className='GraphDisplay-Date-Changer-Bar Bottom Left'></div>
                                        </div>
                                        <div>{formatDate(date)}</div>
                                        <div className='GraphDisplay-Date-Changer' style={{ visibility: isToday(date) ? 'hidden' : 'visible' }} onClick={handleDateForwardClick}>
                                            <div style={darkMode ? {backgroundColor: '#D9D9D9'} : {backgroundColor: '#808080'}} className='GraphDisplay-Date-Changer-Bar Top Right'></div>
                                            <div style={darkMode ? {backgroundColor: '#D9D9D9'} : {backgroundColor: '#808080'}} className='GraphDisplay-Date-Changer-Bar Bottom Right'></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='Dashboard-Body-Overview-Row-Element-Battery-Data-Graph'>
                                    {activeItem === 0 && <BatteryChart triggerDataRetrieval={triggerDataRetrieval} TriggerActivity={TriggerActivity} serialNr={serialNr}/>}
                                </div>
                            </div>
                        </div>
                    </>
                }
            </>
            }
        </>
    )

}

export default BatteryStats;
export const formatCurrency = (value, kommagetal = 2) => {
    return new Intl.NumberFormat('nl-BE', { 
        style: 'currency', 
        currency: 'EUR',
        minimumFractionDigits: kommagetal,
    }).format(value)
}

export const formatNumber = (value) => {
    return new Intl.NumberFormat('nl-BE').format(value)
}
import React from 'react';
import './partnersPage.css';

const partners = [
    {
        name: 'Rescert',
        logo: require("../../../Images/ecovolution-partner-rescert.png"),
        description: 'Rescert is een certificeringsinstantie voor installateurs van hernieuwbare energie, die hoge normen in de sector garandeert.',
        website: 'https://www.rescert.be'
    },
    {
        name: 'Flux50',
        logo: require("../../../Images/redactor.jpg"),
        description: 'Flux50 is een toonaangevende energiecluster in België die zich richt op slimme energieoplossingen en innovatie.',
        website: 'https://www.flux50.com'
    },
    {
        name: 'Agoria',
        logo: require("../../../Images/AGORIA_LOGO_CMYK-POS.jpg"),
        description: 'Agoria is de federatie voor de technologische industrie, die innovatie en duurzaamheid promoot.',
        website: 'https://www.agoria.be'
    },
    {
        name: 'Nelectra',
        logo: require("../../../Images/Logo Nelectra 1410.png"),
        description: 'Nelectra is een beroepsvereniging die elektrische installatiebedrijven in België vertegenwoordigt.',
        website: 'https://www.nelectra.be'
    }
];

const PartnersPage = () => {
    return (
        <div className="partners-page">
            <h1>Onze Partners</h1>
            <div className="partners-grid">
                {partners.map((partner, index) => (
                    <div key={index} className="partner-card">
                        <div className="partner-logo-container">
                            <img
                                src={partner.logo}
                                alt={`${partner.name} logo`}
                                className="partner-logo"
                            />
                        </div>
                        <h2>{partner.name}</h2>
                        <p>{partner.description}</p>
                        <a href={partner.website} target="_blank" rel="noopener noreferrer" className="partner-link">
                            Bezoek Website
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PartnersPage;

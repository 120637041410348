import './dashboardSideNavbar.css'
import DashboardIcon from "../../../Images/icons8-dashboard-100.white.png"
import DashboardIconSelected from "../../../Images/icons8-dashboard-100.blue.png"
import CalendarIcon from "../../../Images/icons8-calendar-100-white.png"
import CalendarIconSelected from "../../../Images/icons8-calendar-100-blue.png"
import LogoutIcon from "../../../Images/icons8-logout-100-white.png"
import LogoutIconSelected from "../../../Images/icons8-logout-100-blue.png"
import SettignsIcon from "../../../Images/settingsIcon.png"
import SettignsIconSelected from "../../../Images/settingsIconBlue.png"
import { UserContext } from '../../../context/UserContext';

import { useContext, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react';

const DashboardSideNavbar = ({changeTab}) => {

    const { userMock, mockLogout } = useContext(UserContext);

    const [itemHovered, setItemHoverd] = useState(false)
    const [selected, setSelected] = useState("Dashboard")

    const { logout } = useAuth0();

    const icons = [
        {name: 'Dashboard', icon: DashboardIcon, selectedIcon: DashboardIconSelected},
        {name: 'Calendar', icon: CalendarIcon, selectedIcon: CalendarIconSelected},
        {name: 'Settings', icon: SettignsIcon, selectedIcon: SettignsIconSelected}
    ]

    const ItemIsBeingHovered = (hoveredItem) => {
        setItemHoverd(hoveredItem)
    }

    const ItemIsSelected = (selectedItem) => {
        
        const response = changeTab(selectedItem)
        if (response) {
            setSelected(selectedItem)
        }
    }

    function Icon({ name, icon, selectedIcon}) {
        return (
            <div onMouseDown={() => ItemIsSelected(name)} onMouseLeave={() => ItemIsBeingHovered("")} onMouseEnter={() => ItemIsBeingHovered(name)} className='Dashboard-Side-Navbar-Item'>
                {itemHovered == name || selected == name ? <img width={30} src={selectedIcon} alt="" /> : <img width={30} src={icon} alt="" />}
                <div className={`icon-text ${itemHovered == name ? 'icon-hovered' : ''}`}>{name}</div>
            </div>
        )
    }

    const LogoutFunction = () => {
        mockLogout()
        logout()
    }

    return (
        <div className="Dashboard-Side-Navbar-container">
            {icons.map((item, index) => (
                <Icon key={index} name={item.name} icon={item.icon} selectedIcon={item.selectedIcon} />
            ))}

            <div onMouseDown={() => LogoutFunction()} className='Dashboard-Side-Navbar-Item-Bottom'>
                <Icon name={"Logout"} icon={LogoutIcon} selectedIcon={LogoutIconSelected} />
            </div>
        </div>
    )
    
}




export default DashboardSideNavbar;
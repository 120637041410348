import React, {createContext, useState, useEffect } from 'react';

const UserContext = createContext();

function UserProvider(props) {
    const [userMock, setUserMock] = useState(null);

    useEffect(() => {
        //optional collect user data
    }, [])

    const login = (userData) => {
        setUserMock(userData);
    };

    const mockLogout = () => {
        setUserMock(null);
    };

    return (
        <UserContext.Provider value={{ userMock, login, mockLogout }}>
            {props.children}
        </UserContext.Provider>
    )
}

export {UserContext, UserProvider}
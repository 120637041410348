import React, { useState, useEffect } from "react"
import { initialKlantData, convertToOptions } from '../../utils/formHelper'
import DayAhead from "../DayAhead/dayAhead"

const Klant = ({
    onEdit, klant, reps, premies, pay,
    setAveragePrice, setAverageLowPrice, setAverageHighPrice,
    isPeriodConfirmed, setIsPeriodConfirmed
}) => {
    const [repData, setRepData] = useState([])
    const [klantData, setKlantData] = useState(klant)
    const [paymentOpties, setPaymentOpties] = useState([])
    const [emailError, setEmailError] = useState("")

    useEffect(() => {
        setRepData(convertToOptions(reps.values))
        setPaymentOpties(convertToOptions(pay))
    }, [reps, pay])

    const handleChange = (e) => {
        const { name, value } = e.target
        setKlantData(prevData => ({ ...prevData, [name]: value }))
    }

    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (name === "email") {
            validateEmail(value);
        }
    }

    useEffect(() => {
        onEdit(klantData)
    }, [klantData, onEdit])

    const eraseForm = () => {
        setKlantData(initialKlantData)
        setEmailError("")
    }

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(email)) {
            setEmailError("Please enter a valid email address.");
        } else {
            setEmailError("");
        }
    }

    return (
        <div className="offerte__container offerte__container--left-aligned">
            <form onSubmit={(e) => e.preventDefault()} className="offerte__form">
                <div className="offerte__form-group offerte__form-group--inline">
                    <div className="offerte__form-item--special">
                        <label className="offerte__label" htmlFor="num">Referentie offerte</label>
                        <input
                            className="offerte__input"
                            type="text"
                            id="num"
                            name="num"
                            placeholder="Referentie offerte"
                            value={klantData.num}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="offerte__form-item">
                        <label className="offerte__label" htmlFor="contactpersoonFirn">Uw FIRN contactpersoon</label>
                        <select
                            className="offerte__select"
                            id="contactpersoonFirn"
                            name="contactpersoonFirn"
                            value={klantData.contactpersoonFirn}
                            onChange={handleChange}
                        >
                            {repData.map((opt, index) => (
                                <option key={index} value={opt.value}>
                                    {opt.text}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="offerte__divider" />
                <h2 className="offerte__header">Contact</h2>
                <div className="offerte__form-group">
                    <label className="offerte__label" htmlFor="bedrijf">Bedrijf</label>
                    <input
                        className="offerte__input"
                        type="text"
                        id="bedrijf"
                        name="bedrijf"
                        placeholder="Bedrijf"
                        value={klantData.bedrijf}
                        onChange={handleChange}
                    />
                </div>
                <div className="offerte__form-group">
                    <label className="offerte__label" htmlFor="contactpersoon">Contactpersoon</label>
                    <input
                        className="offerte__input"
                        type="text"
                        id="contactpersoon"
                        name="contactpersoon"
                        placeholder="Contactpersoon"
                        value={klantData.contactpersoon}
                        onChange={handleChange}
                    />
                </div>
                <div className="offerte__form-group offerte__form-group--inline">
                    <div className="offerte__form-item">
                        <label className="offerte__label" htmlFor="email">Email</label>
                        <input
                            className="offerte__input"
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Email"
                            value={klantData.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            autoComplete="email"
                        />
                        {emailError && <span className="offerte__warning">{emailError}</span>}
                    </div>
                    <div className="offerte__form-item">
                        <label className="offerte__label" htmlFor="tel">Telefoonnummer</label>
                        <input
                            className="offerte__input"
                            type="tel"
                            id="tel"
                            name="tel"
                            placeholder="Telefoonnummer"
                            value={klantData.tel}
                            onChange={handleChange}
                            autoComplete="tel"
                        />
                    </div>
                </div>
                <div className="offerte__form-group">
                    <label className="offerte__label" htmlFor="adres">Straat + Nummer</label>
                    <input
                        className="offerte__input"
                        type="text"
                        id="adres"
                        name="adres"
                        placeholder="Straat + Nummer"
                        value={klantData.adres}
                        onChange={handleChange}
                    />
                </div>
                <div className="offerte__form-group offerte__form-group--inline">
                    <div className="offerte__form-item--special">
                        <label className="offerte__label" htmlFor="postcode">Postcode</label>
                        <input
                            className="offerte__input"
                            type="text"
                            id="postcode"
                            name="postcode"
                            placeholder="Postcode"
                            value={klantData.postcode}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="offerte__form-item">
                        <label className="offerte__label" htmlFor="gemeente">Gemeente</label>
                        <input
                            className="offerte__input"
                            type="text"
                            id="gemeente"
                            name="gemeente"
                            placeholder="Gemeente"
                            value={klantData.gemeente}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="offerte__form-item">
                        <label className="offerte__label" htmlFor="land">Land</label>
                        <input
                            className="offerte__input"
                            type="text"
                            id="land"
                            name="land"
                            placeholder="Land"
                            value={klantData.land}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="offerte__form-group offerte__form-group--inline">
                    <div className="offerte__form-item--special">
                        <label className="offerte__label" htmlFor="korting">Commerciële korting</label>
                        <input
                            className="offerte__input"
                            type="number"
                            id="korting"
                            name="korting"
                            step="1"
                            placeholder="Commerciële korting"
                            value={klantData.korting}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="offerte__form-item">
                        <label className="offerte__label" htmlFor="btw">BTW Nummer</label>
                        <input
                            className="offerte__input"
                            type="text"
                            id="btw"
                            name="btw"
                            placeholder="BTW Nummer"
                            value={klantData.btw}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="offerte__form-group offerte__form-group--inline">
                    <div className="offerte__form-item--special">
                        <label className="offerte__label" htmlFor="geldigTot">Offerte geldig tot</label>
                        <input
                            className="offerte__input"
                            type="date"
                            id="geldigTot"
                            name="geldigTot"
                            value={klantData.geldigTot}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="offerte__form-item">
                        <label className="offerte__label" htmlFor="betalingsvoorwaarde">Betalingsvoorwaarden</label>
                        <select
                            className="offerte__select"
                            id="betalingsvoorwaarde"
                            name="betalingsvoorwaarde"
                            value={klantData.betalingsvoorwaarde}
                            onChange={handleChange}
                        >
                            <option value="" disabled>Selecteer betalingsvoorwaarde</option>
                            {paymentOpties.map((opt, index) => (
                                <option key={index} value={opt.value}>
                                    {opt.text}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="offerte__divider" />
                <DayAhead
                    setAveragePrice={setAveragePrice}
                    setAverageLowPrice={setAverageLowPrice}
                    setAverageHighPrice={setAverageHighPrice}
                    isPeriodConfirmed={isPeriodConfirmed}
                    setIsPeriodConfirmed={setIsPeriodConfirmed}
                />
                <div className="offerte__divider" />
                <h2 className="offerte__header">Verbruik</h2>
                <div className="offerte__form-group offerte__form-group--inline">
                    <div className="offerte__form-item">
                        <label className="offerte__label" htmlFor="verbruik">Jaarverbruik in kWh</label>
                        <input
                            className="offerte__input"
                            type="number"
                            id="verbruik"
                            name="verbruik"
                            min="0"
                            placeholder="Jaarverbruik in kWh"
                            value={klantData.verbruik}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="offerte__form-item">
                        <label className="offerte__label" htmlFor="productie">Productie PV in kWh</label>
                        <input
                            className="offerte__input"
                            type="number"
                            id="productie"
                            name="productie"
                            min="0"
                            placeholder="Productie PV in kWh"
                            value={klantData.productie}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="offerte__form-item">
                        <label className="offerte__label" htmlFor="injectie">Injectie in kWh</label>
                        <input
                            className="offerte__input"
                            type="number"
                            id="injectie"
                            name="injectie"
                            min="0"
                            placeholder="Injectie in kWh"
                            value={klantData.injectie}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="offerte__divider" />
                <h2 className="offerte__header">Prijs</h2>
                <div className="offerte__form-group offerte__form-group--inline">
                    <div className="offerte__form-item">
                        <label className="offerte__label" htmlFor="aankoop">Aankoop totaalkost per kWh</label>
                        <input
                            className="offerte__input"
                            type="number"
                            id="aankoop"
                            name="aankoop"
                            min="0"
                            step="0.001"
                            placeholder="Aankoop totaalkost per kWh"
                            value={klantData.aankoop}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="offerte__form-item">
                        <label className="offerte__label" htmlFor="verkoop">Verkoop injectie PV per kWh</label>
                        <input
                            className="offerte__input"
                            type="number"
                            id="verkoop"
                            name="verkoop"
                            min="0"
                            step="0.001"
                            placeholder="Verkoop injectie PV per kWh"
                            value={klantData.verkoop}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="offerte__form-item">
                        <label className="offerte__label" htmlFor="aankoopExcl">Aankoop elektriciteit excl. Transport etc</label>
                        <input
                            className="offerte__input"
                            type="number"
                            id="aankoopExcl"
                            name="aankoopExcl"
                            min="0"
                            step="0.001"
                            placeholder="Aankoop elektriciteit excl. Transport etc"
                            value={klantData.aankoopExcl}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="offerte__divider" />
                <h2 className="offerte__header">Aansluitvermogen installatie</h2>
                <div className="offerte__form-group">
                    <label className="offerte__label" htmlFor="aansluitvermogen">Aansluitvermogen installatie (in kVA)</label>
                    <input
                        className="offerte__input"
                        type="number"
                        id="aansluitvermogen"
                        name="aansluitvermogen"
                        min="0"
                        max="100"
                        step="1"
                        placeholder="Aansluitvermogen installatie (in kVA)"
                        value={klantData.aansluitvermogen}
                        onChange={handleChange}
                    />
                </div>
                <div className="offerte__divider" />
                <h2 className="offerte__header">Onbalans Simulatie Berekening</h2>
                <div className="offerte__form-group">
                    <label className="offerte__label" htmlFor="simOnbalans">Onbalans Simulatie Berekening (in %)</label>
                    <input
                        className="offerte__input"
                        type="number"
                        id="simOnbalans"
                        name="simOnbalans"
                        min="0"
                        max="100"
                        step="1"
                        placeholder="Onbalans Simulatie Berekening (in %)"
                        value={klantData.simOnbalans}
                        onChange={handleChange}
                    />
                </div>
                <div className="offerte__divider" />
                <h2 className="offerte__header">Premies</h2>
                <div className="offerte__form-group offerte__form-group--inline">
                    {Object.keys(premies).map((o) => (
                        <div className="offerte__form-item" key={o}>
                            <span className="offerte__label">{premies[o].label}</span>
                            {Object.keys(premies[o].options).map((v) => (
                                <div key={v} className="offerte__form-radio">
                                    <input
                                        className="offerte__radio-input"
                                        type="radio"
                                        id={`premie-${o}-${v}`}
                                        name={o}
                                        value={v}
                                        checked={klantData[o] === v}
                                        onChange={handleChange}
                                        autoComplete="off"
                                    />
                                    <label className="offerte__radio-label" htmlFor={`premie-${o}-${v}`}>
                                        {premies[o].options[v].label}
                                    </label>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
                <button type="button" className="offerte__reset-button" onClick={eraseForm}>
                    Reset
                </button>
            </form>
        </div>
    )
}

export default Klant
import './newsPage.css';
import React from 'react';
import { formatDate, newsItems } from './NewsItems';

const NewsPage = () => {
    const sortedNewsItems = newsItems.sort((a, b) => b.date - a.date);

    return (
        <div className="newsPage__body">
            <div className="newsPage__grid">
                {sortedNewsItems.map(item => (
                    <div key={item.id} className="newsPage__item">
                        <img src={item.imgSrc} alt={`News Image ${item.id}`} />
                        <div className="newsPage__details">
                            <p className="newsPage__date">{formatDate(item.date)}</p>
                            <p className="newsPage__text">{item.text}</p>
                            <p className="newsPage__hashtags">{item.hashtags}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default NewsPage;

export const oplossingenItems = [
    {
        id: 1,
        naam: "Batterij Toren",
        foto: require("../../../Images/batteryStack.png"),
        korteBeschrijving: "De FIRN Batterij Toren biedt betrouwbare energieopslag met flexibele schaalbaarheid en geavanceerde energiebeheeropties.",
        voordelen: [
            "Ideaal voor kleine industrieën",
            "Voor binnentoepassingen",
            "Buitenkast beschikbaar"
        ],
        link: "BatteryTower"
    },
    {
        id: 2,
        naam: "Powerblock",
        foto: require("../../../Images/powerblock.png"),
        korteBeschrijving: "De alles-in-één vloeistof gekoelde ESS-kast maakt gebruik van geavanceerde vloeistofkoeling op kastniveau.",
        voordelen: [
            "Krachtige energieopslag",
            "Langdurige prestaties",
            "Geavanceerde koeling"
        ],
        link: "Powerblock"
    },
    {
        id: 3,
        naam: "Controller",
        foto: require("../../../Images/Controller.png"),
        korteBeschrijving: "De FIRN-controller is een slim energiebeheersysteem dat dynamische energietarieven en -verbruik optimaliseert.",
        voordelen: [
            "Slim beheer van energieverbruik",
            "Dynamische aansturing van apparaten",
            "Eenvoudige integratie met meerdere systemen"
        ],
        link: "/Controller#firn-controller"
    },
    {
        id: 4,
        naam: "Container",
        foto: require("../../../Images/container.png"),
        korteBeschrijving: "De FIRN Container is een robuuste, op maat gemaakte oplossing voor energieopslag. Biedt duurzaamheid, bescherming en schaalbaarheid voor elke klant.",
        voordelen: [
            "Op maat gemaakt",
            "Schaalbaar voor grote projecten",
            "Robuust en weersbestendig"
        ],
        link: "Container"
    }
];
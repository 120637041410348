import React from 'react';
import { AuthProvider } from 'react-auth-kit';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { Auth0ProviderWithNavigate } from './auth0-provider-with-navigate';
import { DarkModeProvider } from './context/DarkModeContext';
import { UserProvider } from './context/UserContext';

createRoot(document.getElementById('root')).render(
  
  <React.StrictMode>
    <DarkModeProvider>
      <UserProvider>
        <BrowserRouter basename='/'>
          <Auth0ProviderWithNavigate>
            <App />
          </Auth0ProviderWithNavigate>
        </BrowserRouter>
      </UserProvider>
    </DarkModeProvider>
  </React.StrictMode>
    
);

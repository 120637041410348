import { useContext, useEffect, useState } from 'react'
import './dashboardSavings.css'

import SavingsMeter from './SavingsMeter/savingsMeter'
import { DarkModeContext } from '../../../../context/DarkModeContext';
import Policy from '../../../../api/policy';
import { Area, AreaChart, CartesianAxis, CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { Tooltip } from 'antd';
import { addHours, eachHourOfInterval, endOfDay, format, startOfDay } from 'date-fns';

const DashboardSavings = ({serialNr, toggleHidden}) => {
    const {darkMode} = useContext(DarkModeContext);
    const [yesterDaySavings] = useState(18);
    const [todaySavings] = useState(8);

    const policyApi = Policy();

    const generateHourlyTicks = () => {
        const start = startOfDay(new Date());
        return Array.from({ length: 24 }, (_, i) => addHours(start, i).getTime());
    };
    
    const hourlyTicks = generateHourlyTicks();

    const [data, setData] = useState([]);


    //collect data
    useEffect(() => {
        let interval;

        const getUnbalancedataWithCheck = () => {
            if (serialNr) {
                getUnbalancedata()
            }
        }

        getUnbalancedataWithCheck();

        interval = setInterval(() => {
            getUnbalancedataWithCheck();
        }, 60000 * 60);

        return () => {
            clearInterval(interval)
        };
        
    }, [serialNr])

    const getUnbalancedata = async() => {
        try {
            const data = await policyApi.getImbalance(serialNr, new Date());
            
            if (!data || !data.element || data.element != "yuso" || data.powerSetpoint_W.length < 1) {
                throw new Error("no correct data");
            }

            const dataReformat = []
            for (let i = 0; i < data.powerSetpoint_W.length; i++) {
                const dateObject = new Date(data.time[i])
                dataReformat.push({"date": dateObject, "value": data.powerSetpoint_W[i] == 0 ? 0 : data.powerSetpoint_W[i] > 0 ? 1000 : -1000})
            }

            //checks every value checks if next value is same value if true then remove value
            let firstValue = true;
            for (let i = 0; i < dataReformat.length; i++) {
                if (firstValue) {
                    firstValue = false
                } else {
                    if (dataReformat[i + 1] && dataReformat[i + 1].value == dataReformat[i].value) {
                        dataReformat.splice(i, 1);
                        i--;
                    } else {
                        firstValue = true
                    }
                }
            }

            // console.log(dataReformat)

        

            for (let i = 0; i < dataReformat.length; i++) {
                
                if (dataReformat[i].value != 0) {

                    let p = i;
                    let j = p + 1;
                    let timeinterval = 0;

                    const originalVal = dataReformat[p].value

                    while (timeinterval < 60000 * 5) {
                        if (dataReformat[j]) {
                            if (originalVal == dataReformat[j].value) {
                                timeinterval = 0;
                                p = j;
                            } else {
                                timeinterval += dataReformat[j].date - dataReformat[p].date
                            }
        
                            j++
                        } else {
                            break;
                        }
                    }

                    const diff = p - i
                    dataReformat.splice(i + 1, diff - 1)
                }
            }
            
            for (let i = 0; i < dataReformat.length; i++) {
                if (dataReformat[i].value != 0) {
                    if (dataReformat[i + 1] && dataReformat[i + 1].value != dataReformat[i].value) {

                        dataReformat.splice(i + 1, 0, { date: new Date(dataReformat[i].date + 2000), value: 0 });

                        i++
                    }
                }
                
            }
            
            let formattedData = formatDataForChart(dataReformat)

            setData(formattedData)
        } catch (error) {
            console.log(error)
            setData(null)
            toggleHidden(true)
            //trigger parent event
        }
        
    }

    const formatDataForChart = (data) => {
        return data.map(item => ({
            ...item,
            timestamp: item.date.getTime()  // Convert Date object to timestamp
        }));
    };


    const ChartComponent = () => {
        return (
            <ResponsiveContainer width="100%" height={360}>
                <LineChart
                    data={data}
                    margin={{ top: 5, right: 30, left: 0, bottom: 10 }}
                >
                    {darkMode ? <CartesianGrid style={{opacity: 0.30}} strokeDasharray="10 10"/> : <CartesianGrid strokeDasharray="3 3" />} 
                    <XAxis 
                        dataKey="timestamp" 
                        type="number" 
                        domain={['dataMin', 'dataMax']} 
                        ticks={hourlyTicks} 
                        tickFormatter={(tick) => format(new Date(tick), 'HH:mm')} 
                        interval={3}
                    />
                    <YAxis axisLine={true}/>
                    <Tooltip />
                    <Line type="monotone" dataKey="value" strokeWidth={2} stroke="#F09D9D" dot={false} />
                </LineChart>
            </ResponsiveContainer>
        );
    };

    return (
        <div style={data && data.length > 0 ? {visibility: 'visible'} : {visibility: 'hidden'}} className="dashboard__savings__container">
            <div className='dashboard__savings__imbalance'>Imbalance</div>
            <ChartComponent />
            
            {/* Uncomment and adjust these sections as needed */}
            {/* <div className={`dashboard__savings__underdevelopment__container__${darkMode ? 'darkmode' : 'lightmode'}`}>
                <div className={`dashboard__savings__underdevelopment__message__${darkMode ? 'darkmode' : 'lightmode'}`}>
                    This section is currently under development and will be available soon.
                </div>
            </div> */}
            {/* <div className='dashboard__savings__header'>Savings</div>
            <div className='dashboard__savings__content'>
                <div className='dashboard__savings__overdrive__container'>
                    <div className='dashboard__savings__overdrive__header'>Yesterday</div>
                    <div className='dashboard__savings__overdrive__meter__container'>
                        <SavingsMeter darkMode={darkMode} price={yesterDaySavings}/>
                    </div>
                    <div className='dashboard__savings__overdrive__value'>{yesterDaySavings}€</div>
                </div>
                <div className='dashboard__savings__overdrive__container'>
                    <div className='dashboard__savings__overdrive__header'>Today</div>
                    <div className='dashboard__savings__overdrive__meter__container'>
                        <SavingsMeter darkMode={darkMode} price={todaySavings}/>
                    </div>
                    <div className='dashboard__savings__overdrive__value'>{todaySavings}€</div>
                </div>
            </div> */}
        </div>
    );
};

export default DashboardSavings;
import './dashboardNavbar.css'
import DashboardNavbarModeSlider from './Dashboard-Navbar-Darkmode/dashboardNavbarModeSlider';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext';

const DashboardNavbar = ({user, superRole, serialNr}) => {

    const navigate = useNavigate();
    const { mockLogout } = useContext(UserContext);

    const returnToAdminPage = () => {
        mockLogout()
        navigate("/AdminPage")
    }

    return (
        <div className="Dashboard-Navbar-container">
            {user &&
            <>
            <div className='Dashboard-Navbar-User-Container'>
                <div className='Dashboard-Navbar-User-Image-Container'>
                    <img className='Dashboard-Navbar-User-Image' src={user.picture ? user.picture : "https://img.freepik.com/premium-vector/young-smiling-man-avatar-man-with-brown-beard-mustache-hair-wearing-yellow-sweater-sweatshirt-3d-vector-people-character-illustration-cartoon-minimal-style_365941-860.jpg"} alt="" />
                </div>
                <div className='Dashboard-Navbar-User-Details-Container'>
                    <div className='Dashboard-Navbar-User-Details-Role'>{user['https://firnenergy.com/roles'] ? user['https://firnenergy.com/roles'] : "User"}</div>
                    <div className='Dashboard-Navbar-User-Details-Email'>{user.email ? user.email : "Placeholder"}</div>
                </div>
            </div>
            <div className='dashboard__navbar__buttons'>
                {superRole && (
                    <button onClick={returnToAdminPage} className='Dashboard-Navbar-Return-Button'>
                        Return to Manager Page
                    </button>
                )}
                <button style={{visibility: serialNr.includes("OM") ? 'visible' : 'hidden'}} onClick={() => window.location.href = 'https://insights.eniris.be/#/login'} className='Dashboard-Navbar-Return-Button tempfixthing'>Advanced view</button>
            </div>
            <div className='Dashboard-Navbar-Adjustables-Container'>
                <div className='Dashboard-Navbar-Adjustables-DarkMode-Container'>
                    <DashboardNavbarModeSlider/>
                </div>
            </div>
            </>
            }
        </div>
    )

}

export default DashboardNavbar;
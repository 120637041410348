import { useContext, useEffect, useState } from "react"
import { UserContext } from "../../../../../context/UserContext";

const SettingsGeneral = () => {

    const { userMock } = useContext(UserContext);

    const [generalData, setGeneralData] = useState({})

    useEffect(() => {
        if (userMock.user_metadata && userMock.user_metadata.settings) {
            setGeneralData(userMock.user_metadata.settings.general)
        }
    }, [userMock])

    return (
        <div className="settings__page__items">
            <div className="settings__page__item">
                <div className="settings__page__item__info">
                    <div className="settings__page__item__title">Name</div>
                    <div className="settings__page__item__desc">{generalData.name ? generalData.name : "Empty"}</div>
                </div>
                <div className="settings__page__item__edit">Edit</div>
            </div>
            <div className="settings__page__item">
                <div className="settings__page__item__info">
                    <div className="settings__page__item__title">Phone</div>
                    <div className="settings__page__item__desc">{generalData.phone ? generalData.phone : "Empty"}</div>
                </div>
                <div className="settings__page__item__edit">Edit</div>
            </div>
            <div className="settings__page__item">
                <div className="settings__page__item__info">
                    <div className="settings__page__item__title">Email</div>
                    <div className="settings__page__item__desc">{generalData.email ? generalData.email : "Empty"}</div>
                </div>
            </div>
            <div className="settings__page__item">
                <div className="settings__page__item__info">
                    <div className="settings__page__item__title">Serial nummer</div>
                    <div className="settings__page__item__desc">{generalData.serialNr ? generalData.serialNr : "Empty"}</div>
                </div>
            </div>
        </div>
    )

}

export default SettingsGeneral
import './graphContainer.css'
import GraphSelectorItem from './GraphSelector/graphSelectorItem'
import { useContext, useEffect, useState } from "react";
import GraphMaker from './GraphMaker/graphMaker';
import { Bar, Line } from 'recharts'

import { DarkModeContext } from '../../../../context/DarkModeContext';
import { DndContext, DragOverlay } from '@dnd-kit/core';
import {Droppable} from '../../../DragAndDrop/Droppable';
import {Draggable} from '../../../DragAndDrop/Draggable';
import GraphValues from '../../../../api/graphValues';

import questionMarkBlack from '../../../../Images/questionMarkBlack.png';
import questionMark from '../../../../Images/questionIcon.png';

import Policy from '../../../../api/policy';

import { format, isToday, parse, subDays, addDays } from 'date-fns';

const GraphContainer = ({serialNr}) => {

    //add new graph value
    //1 Add a useState
    //2 update valueDatas

    const [date, setDate] = useState(format(new Date(), 'dd-MM-yyyy'))
    const [isInfoHovered, setIsInfoHovered] = useState(false);
    const {darkMode} = useContext(DarkModeContext);
    const [rememberIds, setRememberIds] = useState([])

    const policyApi = Policy()
    const graphValueApi = GraphValues();

    // Initial graph data configuration
    const initialData = [
        { id: 1, name: "production", color: "#DABB50", chartObject: <Bar key={1} dataKey={'production'} fill='#DABB50' radius={[5, 5, 0, 0]}/>, requestName: "pv_power", data: [] },
        { id: 2, name: "consumption", color: "#CE80EA", chartObject: <Bar key={2} dataKey={'consumption'} fill='#CE80EA' radius={[5, 5, 0, 0]}/>, requestName: "consumer_power", data: [] },
        { id: 3, name: "export to grid", color: "#F88C8C", chartObject: <Bar key={3} dataKey={'export to grid'} fill='#F88C8C' radius={[5, 5, 0, 0]}/>, requestName: "grid_export_power", data: [] },
        { id: 4, name: "import from grid", color: "#46B6BD", chartObject: <Bar key={4} dataKey={'import from grid'} fill='#46B6BD' radius={[5, 5, 0, 0]}/>, requestName: "grid_import_power", data: [] },
        // { id: 5, name: "imbalance", color: "red", chartObject: <Bar key={5} dataKey={'import from grid'} fill='red' radius={[5, 5, 0, 0]}/>, requestName: "imbalance", data: [] },
    
    ];

    // State to hold the list of items (graphs)
    const [items, setItems] = useState(initialData)

    //fetches data whenever serialNr or date changes
    useEffect(() => {
        if (serialNr) {
            const requestGraphValueData = async () => {
                const data = await graphValueApi.getGraphData(serialNr, parse(date, 'dd-MM-yyyy', new Date()));

                // Update items with the fetched data
                setItems((prevItems) =>
                    prevItems.map((item) => {
                        const newData = [];
    
                        if (data[`${item.requestName}`] && data[`${item.requestName}`].time) {
                            for (let i = 0; i < data[`${item.requestName}`].time.length; i++) {
                                newData.push({
                                    date: new Date(data[`${item.requestName}`].time[i]),
                                    value: data[`${item.requestName}`].values[i] < 0 ? -(data[`${item.requestName}`].values[i]) : data[`${item.requestName}`].values[i],
                                });
                            }
                            
                            return { ...item, data: newData };
                        } else {
                            return { ...item, data: [] };
                        }
                    })
                );
            };
            requestGraphValueData();
        }
    }, [serialNr, date]);

    useEffect(() => {
        if (rememberIds.length > 0 && items[items.length - 1].data && items[items.length - 1].data.length > 0) {

            rememberItemsFromBefore()
        }
    }, [items])

    // State for time-based data structure
    const [data, setData] = useState([]);

    // State to hold the list of chart objects and selected items
    const [chartObjects, setChartObjects] = useState([])
    const [selectedItems, setSelectedItems] = useState([]);
    const [activeItem, setActiveItem] = useState(null); //holds the currently dragged item

    const rememberItemsFromBefore = () => {
        handleItemListAdded()
        // for (let i = 0; i < rememberIds.length; i++) {

        //     const newItem = items.find((element) => element.id === rememberIds[i]);
        //     handleSelectedItemAdded(newItem)
        // }

        setRememberIds([])
    }

    function handleMouseEnter() {
        setIsInfoHovered(true)
    }

    function handleMouseLeave() {
        setIsInfoHovered(false)
    }

    function handleDragStart(event) {
        setActiveItem(items.find((element) => element.id === event.active.id));
    }

    // When dragging ends, handle dropping into the graph
    function handleDragEnd(event) { 

        if (event.over && event.over.id == "droppable") {

            const newItem = items.find((element) => element.id === event.active.id);
           
            handleSelectedItemAdded(newItem)
        }

        setActiveItem(null)
    }

    const handleItemListAdded = () => {
        let newData = []
        for (let i = 0; i < rememberIds.length; i++) {

            
            const newItem = items.find((element) => element.id === rememberIds[i]);
            const newItemData = newItem.data

            if (newItemData.length !== 0) {
                // let newData = []
                if (!newData || newData.length < 1) {
                    newData = newItemData.map((item) => {
                        return {
                            date: item.date,
                            [newItem.name]: item.value
                        }
                    })
                } else {
                    newData = newData.map((entry, i) => ({
                        ...entry,
                        [newItem.name] : newItemData[i] == undefined || newItemData[i].value == null  ? null : newItemData[i].value
                    }))
                }
    
                const newChartObject = {'id': newItem.id, 'object': newItem.chartObject}
                setChartObjects((prevChartObjects) => [
                    ...prevChartObjects,
                    newChartObject
                ])
    
                setSelectedItems((prevSelectedItems) => [
                    ...prevSelectedItems,
                    newItem
                ]);
    
                //remove the item from the available list
                setItems((prevItems) => 
                    prevItems.filter((item) => item.id !== newItem.id)
                );
            }
        }

        const formatData = formatDataForChart(newData)
        setData(formatData)
        
    }

    const handleSelectedItemAdded = (newItem) => {

        const newItemData = newItem.data

        if (newItemData.length !== 0) {
            let newData = []
            if (!data || data.length < 1) {
                newData = newItemData.map((item) => {
                    return {
                        date: item.date,
                        [newItem.name]: item.value
                    }
                })
            } else {
                newData = data.map((entry, i) => ({
                    ...entry,
                    [newItem.name] : newItemData[i] == undefined || newItemData[i].value == null  ? null : newItemData[i].value
                }))
            }

            const formatData = formatDataForChart(newData)

            setData(formatData)

            const newChartObject = {'id': newItem.id, 'object': newItem.chartObject}
            setChartObjects((prevChartObjects) => [
                ...prevChartObjects,
                newChartObject
            ])

            setSelectedItems((prevSelectedItems) => [
                ...prevSelectedItems,
                newItem
            ]);

            //remove the item from the available list
            setItems((prevItems) => 
                prevItems.filter((item) => item.id !== newItem.id)
            );
        }
    }

    const handleSelectedItemRemove = (name) => {

        const foundItem = selectedItems.find((element) => element.name == name);

        setItems((prevItems) => {
            const updatedItems = [...prevItems, foundItem];
            return updatedItems.sort((a, b) => a.id - b.id);
        })
        setSelectedItems((prevSelectedItems) => 
            prevSelectedItems.filter((item) => item.id !== foundItem.id)
        );
        setChartObjects((prevChartObjects) =>
            prevChartObjects.filter((item) => item.id !== foundItem.id)
        );
    }
    
    
    const handleDateBackClick = () => {
        setItems(initialData)

        const ids = selectedItems.map((item) => item.id)
        setRememberIds(ids)

        setChartObjects([])
        setSelectedItems([])
        setData([])

        const parsedDate = parse(date, 'dd-MM-yyyy', new Date());
        const dateBefore = subDays(parsedDate, 1);

        setDate(format(dateBefore, 'dd-MM-yyyy'))
    }

    const handleDateForwardClick = () => {
        const parsedDate = parse(date, 'dd-MM-yyyy', new Date());
        //set data empty so loading triggers?
        //reset items list?
        if (!isToday(parsedDate)) {
            setItems(initialData)

            const ids = selectedItems.map((item) => item.id)
            
            setRememberIds(ids)

            setChartObjects([])
            setSelectedItems([])
            setData([])

            const dateAfter = addDays(parsedDate, 1);
            setDate(format(dateAfter, 'dd-MM-yyyy'));
        }
    }

    const formatDataForChart = (data) => {
        return data.map(item => ({
            ...item,
            timestamp: new Date(item.date).getTime()  // Convert Date object to timestamp
        }));
    };

    return (
        <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
            <div className='GraphContainer'>
                <div className={`GraphSelector ${activeItem ? 'no-scroll' : ''} ${isInfoHovered ? `${darkMode? 'infoHoveredDarkmode' : 'infoHovered'}`: ''}`} style={{border: darkMode ?  '#3a3a3a solid 2px' : '#CBCBCB solid 2px'}}>
                    {items.map(item => 
                        <Draggable key={item.id} id={item.id}>
                            <GraphSelectorItem 
                                Name={item.name} 
                                Color={item.color} 
                                Data={item.data} 
                                GradientId={item.id}
                            />
                        </Draggable>
                    )}
                </div>

                <DragOverlay>
                    {activeItem ? (
                        <GraphSelectorItem 
                            Name={activeItem.name} 
                            Color={activeItem.color} 
                            Data={activeItem.data} 
                            GradientId={activeItem.id}
                    />
                    ) : null}
                </DragOverlay>

                <div className='GraphDisplay' style={{border: darkMode ?  '#3a3a3a solid 2px' : '#CBCBCB solid 2px', position: 'relative'}}>
                    <div className='GraphDisplay-Date-Container'>
                        <div style={darkMode ? {backgroundColor: '#353535', border: '2px solid #606060'} : {backgroundColor: '#D0D0D0', border: '2px solid #A0A0A0'}} className='GraphDisplay-Date-Category-Container'>
                            <div>Day</div>
                        </div>
                        <div style={darkMode ? {backgroundColor: '#353535', border: '2px solid #606060'} : {backgroundColor: '#D0D0D0', border: '2px solid #A0A0A0'}} className='GraphDisplay-Date-Date-Container'>
                            <div className='GraphDisplay-Date-Changer' onClick={handleDateBackClick}>
                                <div style={darkMode ? {backgroundColor: '#D9D9D9'} : {backgroundColor: '#808080'}} className='GraphDisplay-Date-Changer-Bar Top Left'></div>
                                <div style={darkMode ? {backgroundColor: '#D9D9D9'} : {backgroundColor: '#808080'}} className='GraphDisplay-Date-Changer-Bar Bottom Left'></div>
                            </div>
                            <div>{date}</div>
                            <div className='GraphDisplay-Date-Changer' onClick={handleDateForwardClick}>
                                <div style={darkMode ? {backgroundColor: '#D9D9D9'} : {backgroundColor: '#808080'}} className='GraphDisplay-Date-Changer-Bar Top Right'></div>
                                <div style={darkMode ? {backgroundColor: '#D9D9D9'} : {backgroundColor: '#808080'}} className='GraphDisplay-Date-Changer-Bar Bottom Right'></div>
                            </div>
                        </div>
                    </div>
                    <Droppable>
                        <GraphMaker date={parse(date, 'dd-MM-yyyy', new Date())} handleSelectedItemRemove={handleSelectedItemRemove} chartObjects={chartObjects} data={data}></GraphMaker>
                    </Droppable>
                    <div className='GraphInfo' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <img width={25} src={darkMode ? questionMark : questionMarkBlack} alt="" />
                        <div className={darkMode ? 'PopupText' : 'PopupTextBlack'}>Drag and drop your desired data into the graph and compare.</div>
                    </div>
                </div>
            </div>
        </DndContext>
    );
}

export default GraphContainer
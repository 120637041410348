import React from 'react';
import './homePageHeader.css';
import HomePageNavBar from './homePageNavbar';
import DayAheadPriceChart from '../dayAheadPrice/dayAheadPriceChart';
import HomePageHeaderBody from './homePageHeaderBody';
import HomePageNewsAlert from '../HomePage-Components/homePageNewsAlert';

import FluxPartner from "../../../Images/redactor.jpg"
import AgoriaPartner from "../../../Images/AGORIA_LOGO_CMYK-POS.jpg"
import NelectraPartner from "../../../Images/Logo Nelectra 1410.png"
import RecertPartner from "../../../Images/ecovolution-partner-rescert.png"

const HomePageHeader = ({ scrollToNews }) => {
    return (
        <div className="homePage__header">
            <HomePageNavBar isHomePage={true} />
            <HomePageNewsAlert scrollToNews={scrollToNews} />
            <div className='homePage__navbar__text__segment'>
                <p className='homePage__navbar__subtitle'>FIRN ENERGY</p>
                <h1 className='homePage__navbar__title'>Verdien op Energieverbruik!</h1>
                <p className='homePage__navbar__description'>Klinkt onmogelijk? Zeker niet! Contacteer ons en ontdek hoe onze AI-oplossing het mogelijk maakt!</p>
            </div>
            <HomePageHeaderBody />
            <div className='homePage__navbar__dayAhead__segment'>
                <div className='homePage__navbar__text-section'>
                    <p className='homePage__navbar__subtitle'>FIRN ENERGY <b>AI SOLUTION</b></p>
                    <h1 className='homePage__navbar__title'>Dagelijks profiteren van enorme prijsverschillen op de energiemarkt</h1>
                    <p>Wij beheren autonoom uw dynamische energietarieven. <br />Day-ahead uurtarieven / onbalansmarkt.</p>
                    <div className="homePage__navbar__links">
                        <a href="/Controller">Ontdek onze controller &rarr;</a>
                        <a href="#">Demo aanvragen &rarr;</a>
                    </div>
                </div>
                <div className="homePage__navbar__graph-section">
                    <div className="homePage__navbar__graph">
                        <DayAheadPriceChart dateToday={new Date()} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomePageHeader;

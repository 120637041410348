import React from 'react';
import './container.css';

import container from '../../../../Images/container.png';
import containerVideo from '../../../../Images/Container.mp4';

const Container = () => {
    return (
        <div className="firnContainer__container">
            <section className="firnContainer__intro">
                <h1>FIRN Container</h1>
                <p>
                    De FIRN Container is een op maat gemaakt energieopslagsysteem dat volledig kan worden aangepast aan de specifieke behoeften van uw bedrijf. Met zijn robuuste constructie en flexibele configuraties biedt deze container een betrouwbare en efficiënte oplossing voor grootschalige energieopslag.
                </p>
            </section>

            <section className="firnContainer__video">
                <h2>Onze Container</h2>
                <video autoPlay loop muted>
                    <source src={containerVideo} type="video/mp4" />
                    Uw browser ondersteunt deze video-indeling niet.
                </video>
            </section>

            <section className="firnContainer__features">
                <h2>Kenmerken</h2>
                <ul>
                    <li><strong>Op Maat Gemaakt:</strong> Flexibele configuraties om aan uw behoeften te voldoen.</li>
                    <li><strong>Robuuste Constructie:</strong> Ontworpen voor langdurige prestaties en veiligheid.</li>
                    <li><strong>Efficiënte Energieopslag:</strong> Geavanceerde technologie voor optimale prestaties.</li>
                    <li><strong>Gemakkelijke Installatie:</strong> Snel en eenvoudig te installeren op uw locatie.</li>
                </ul>
            </section>

            <section className="firnContainer__image">
                <img src={container} alt="FIRN Container" />
            </section>
        </div>
    );
}

export default Container;
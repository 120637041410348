import React, { useState, useEffect } from 'react'
import { formatCurrency } from '../../../utils/helpers'
import { format } from 'date-fns'
import html2pdf from 'jspdf-html2canvas';

const apiURL = (process.env.NODE_ENV === 'development') ? 'http://localhost/FirnForm/data' : `https://offertes.firnenergy.com/data`

const OfferteExtra = ({ totalOrder, updateTotalOrder, klant, paymentOptions, reps }) => {
    const [currKleine, setCurrKleine] = useState({
        klOmschrijving: "",
        klAantal: 0,
        klEenheid: 0
    })
    const [sendingEmail, setSendingEmail] = useState(false)
    const [emailStatus, setEmailStatus] = useState(null)
    const [emailSent, setEmailSent] = useState(false)
    const [validationError, setValidationError] = useState("")

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCurrKleine(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleRemove = (index) => {
        const updatedList = [...totalOrder.kleineMaterialen.list];
        updatedList.splice(index, 1);
        const newTotal = updatedList.reduce((acc, item) => acc + (item.klAantal * item.klEenheid), 0);
        updateTotalOrder({ ...totalOrder, kleineMaterialen: { list: updatedList, total: newTotal } });
    };

    const handleSubmit = () => {
        if (!currKleine.klOmschrijving || currKleine.klAantal <= 0 || currKleine.klEenheid <= 0) {
            setValidationError("Vul alle velden in voordat je iets kunt toevoegen.");
            return;
        }

        const newItem = {
            klOmschrijving: currKleine.klOmschrijving,
            klAantal: parseInt(currKleine.klAantal, 10),
            klEenheid: parseFloat(currKleine.klEenheid, 10)
        };
        const updatedList = [...totalOrder.kleineMaterialen.list, newItem];
        const newTotal = updatedList.reduce((acc, item) => acc + (item.klAantal * item.klEenheid), 0);
        updateTotalOrder({ ...totalOrder, kleineMaterialen: { list: updatedList, total: newTotal } });

        setCurrKleine({
            klOmschrijving: '',
            klAantal: 0,
            klEenheid: 0
        });
        setValidationError("");
    };

    useEffect(() => {
        if (emailStatus) {
            const timer = setTimeout(() => setEmailStatus(null), 5000)
            return () => clearTimeout(timer)
        }
    }, [emailStatus])

    const savePdf = async () => {
        setEmailStatus(null)

        try {
            const pages = document.getElementsByClassName('offerte__page')
            const pdf = await html2pdf(pages, {
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'px', format: 'letter', orientation: 'portrait' },
                margin: { top: 10, right: 15, bottom: 2, left: 15 },
                watermark({ pdf, pageNumber, totalPageNumber }) {
                    pdf.setTextColor('#444')
                    pdf.setFontSize(10)
                    pdf.text(5, pdf.internal.pageSize.height - 8, `Firnenergy.com - ${format(new Date(), 'dd/MM/yyyy')} - ${pageNumber}/${totalPageNumber}`)
                },
                output: 'offerte.pdf'
            })

            const options = new FormData()
            options.append('to', klant.email)
            options.append('cc', reps.values[klant.rep]?.mail || 'sales@firnenergy.com')
            options.append('subject', 'Offerte Firnenergy')
            options.append('myBlob', pdf.output('blob'))

            const response = await fetch(`${apiURL}/sendMail/`, {
                method: 'POST',
                body: options,
                redirect: 'follow'
            })

            const result = await response.json()
            if (result.success) {
                setEmailStatus('Uw mail is succesvol verzonden.')
                setEmailSent(true)
            } else {
                setEmailStatus('Er was een probleem met het versturen van uw e-mail.')
                if (result.error) setEmailStatus(prev => `${prev} ${result.error}`)
            }
        } catch (error) {
            setEmailStatus('Er was een probleem met het versturen van uw e-mail.')
        } finally {
            setSendingEmail(false)
        }
    }

    return (<>
        <div data-html2canvas-ignore='true' className='offerte__divider offerte__divider--page-break'></div>

        <div>
            <h2 data-html2canvas-ignore={totalOrder.kleineMaterialen.list.length === 0 && 'true'} className='offerte__header'>
                Kleine Materialen/ Diversen
            </h2>
            {validationError && <div className='offerte__warning'>{validationError}</div>}
            <table data-html2canvas-ignore={totalOrder.kleineMaterialen.list.length === 0 && 'true'} className='offerte__table offerte__table--celled'>
                <thead className='offerte__table__thead'>
                    <tr className='offerte__table__row'>
                        <th className='offerte__table__header offerte__table__header--eight-wide'>Omschrijving</th>
                        <th className='offerte__table__header offerte__table__header--two-wide'>Aantal</th>
                        <th className='offerte__table__header offerte__table__header--three-wide'>Eenheidsprijs</th>
                        <th className='offerte__table__header offerte__table__header--two-wide'>Totaal</th>
                        <th className='offerte__table__header offerte__table__header--one-wide'>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        totalOrder.kleineMaterialen.list.map((item, index) => (
                            <tr className='offerte__table__row' key={index}>
                                <td className='offerte__table__cell'>{item.klOmschrijving}</td>
                                <td className='offerte__table__cell'>{item.klAantal}</td>
                                <td className='offerte__table__cell'>{formatCurrency(item.klEenheid)}</td>
                                <td className='offerte__table__cell'>{formatCurrency(item.klAantal * item.klEenheid)}</td>
                                <td className='offerte__table__cell'>
                                    <button className="offerte__button offerte__icon-button offerte__button--negative offerte__button--fluid" onClick={() => handleRemove(index)}>
                                        <i className='fas fa-minus offerte__icon'></i>
                                    </button>
                                </td>
                            </tr>
                        ))
                    }
                    <tr className='offerte__table__row'>
                        <td className='offerte__table__cell'>
                            <input type="text" name="klOmschrijving" onChange={handleChange} value={currKleine.klOmschrijving} className='offerte__input' />
                        </td>
                        <td className='offerte__table__cell'>
                            <input type="number" name="klAantal" min="0" onChange={handleChange} value={currKleine.klAantal} className='offerte__input' />
                        </td>
                        <td className='offerte__table__cell'>
                            <input type="number" name="klEenheid" min="0" step="0.01" onChange={handleChange} value={currKleine.klEenheid} className='offerte__input' />
                        </td>
                        <td className='offerte__table__cell' colSpan={2}>
                            <button className="offerte__button offerte__icon-button offerte__button--positive offerte__button--fluid" onClick={() => handleSubmit()}>
                                <i className='fas fa-plus offerte__icon'></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
                <tfoot className='offerte__table__footer'>
                    <tr className='offerte__table__row'>
                        <th colSpan={3} className='offerte__table__header'>Totaal (excl. BTW):</th>
                        <th colSpan={2} className='offerte__table__header'>{formatCurrency(totalOrder.kleineMaterialen.total)}</th>
                    </tr>
                </tfoot>
            </table>
        </div>

        <div className='offerte__page'>
            <div className='offerte__divider'></div>
            <h3 className='offerte__header'>Algemene voorwaarden:</h3>
            <i>Algemene voorwaarden en algemene voorwaarden inzake aankoop goederen/hardware worden bezorgd aan de klant samen met de offerte. Aanvaarden van de offerte veronderstelt het aanvaarden van de algemene voorwaarden en de algemene voorwaarden inzake aankoop goederen/hardware. Beide documenten zijn steeds raadpleegbaar op onze website www.firnenergy.com of op aanvraag.</i>
            <h3 className='offerte__header'>Offerte geldig tot:</h3>
            <p>{format((klant.geldigTot), 'dd/MM/yyyy')}</p>
            {klant.betalingsvoorwaarde !== "" && (
                <>
                    <h3 className='offerte__header'>Betalingsvoorwaarden:</h3>
                    <p>{paymentOptions[klant.betalingsvoorwaarde]}</p>
                </>
            )}

            <div className='offerte__segment'>
                <div className='offerte__grid offerte__grid--celled offerte__grid--equal-width'>
                    <div className='offerte__row'>
                        <div className='offerte__column offerte__column--four offerte__column--wide'>Datum bestelling:</div>
                        <div className='offerte__column'></div>
                    </div>
                    <div className='offerte__row'>
                        <div className='offerte__column offerte__column--four offerte__column--wide'>Naam:</div>
                        <div className='offerte__column'></div>
                    </div>
                    <div className='offerte__row'>
                        <div className='offerte__column offerte__column--four offerte__column--wide'>Handtekening:</div>
                        <div className='offerte__column'></div>
                    </div>
                </div>
            </div>
        </div>

        <div className={totalOrder.kleineMaterialen.list.length > 0 ? 'offerte__divider offerte__divider--page-break' : 'offerte__divider'} data-html2canvas-ignore="true"></div>

        <button
            data-html2canvas-ignore='true'
            className={`offerte__button offerte__icon-button offerte__button--massive offerte__button--fluid offerte__button--left offerte__button--labeled ${!sendingEmail ? 'offerte__button--blue' : 'offerte__button--lightblue'}`}
            onClick={() => {
                if (!sendingEmail && !emailSent) {
                    setSendingEmail(true);
                    savePdf();
                }
            }}
            disabled={sendingEmail || emailSent}
        >
            <span className="offerte__icon fas fa-paper-plane"></span>
            {sendingEmail ? 'Versturen...' : (emailSent ? 'Offerte Verzonden' : 'Verstuur offerte')}
        </button>
        {emailStatus && <div className="offerte__warning">{emailStatus}</div>}
        <div className="offerte__divider" data-html2canvas-ignore="true"></div>
    </>)
}

export default OfferteExtra

import React from 'react';
import batterydisplay from '../../../../Images/batteryStack.png';
import screenDisplay from '../../../../Images/Schermafbeelding 2023-08-16 164524slanted.png';

const Industrie = () => {
    return (
        <div className="sectoren__body">
            <h1 className='sectoren__header'>Industrie</h1>
            <div className="sectoren__section">
                <div className="sectoren__text sectoren__text--brown">
                    <h2>FIRN Energie Storage</h2>
                    <p>De industriële energie opslag is het buffervat om uw eigen verbruik te optimaliseren en optimaal van uw eigen energie gebruik te kunnen maken.</p>
                    <ul>
                        <li>MODULAIR SYSTEEM van 40kWh tot 4000kWh</li>
                        <li>In technische ruimte of ENERGY CONTAINER</li>
                    </ul>
                </div>
                <div className="sectoren__image">
                    <img src={batterydisplay} alt="Battery Display" />
                </div>
            </div>

            <div className="sectoren__section">
                <img src={screenDisplay} alt="Screen Display" className="sectoren__image" />
                <div className="sectoren__text">
                    <h2>FIRN laadpalen</h2>
                    <p>De TellToCharge laadpalen integreren in het totaal systeem is een evidentie.</p>
                    <h2>FIRN energiemetingen</h2>
                    <p>Energie productie en verbruiken meten en visualiseren blijft altijd een basis van een energie management systeem.</p>
                    <h2>FIRN visualisatie</h2>
                    <p>Energie productie en verbruiken meten en visualiseren, besparingen in kaart brengen zijn mogelijk via een app en Website.</p>
                </div>
            </div>
        </div>
    )
}

export default Industrie;
import { useContext, useEffect, useState } from 'react'
import './settingsRelaisItem'
import Settings from '../../../../../../api/settings'
import { DarkModeContext } from '../../../../../../context/DarkModeContext'

const SettingsRelaisItem = ({serialNr, relay}) => {

    const settingsApi = Settings()
    const {darkMode} = useContext(DarkModeContext);

    const [relais, setRelais] = useState([
        {title: "Name", desc: relay.name, edit: {active: false, prevVal: ''}},
        {title: "Node_id", desc: relay.node_id, edit: {active: false, prevVal: ''}},
        {title: "Provider", desc: relay.gateway, edit: {active: false, prevVal: ''}}
    ])

    useEffect(() => {
        setRelais([
            {title: "Name", desc: relay.name, edit: {active: false, prevVal: ''}},
            {title: "Node_id", desc: relay.node_id, edit: {active: false, prevVal: ''}},
            {title: "Provider", desc: relay.gateway, edit: {active: false, prevVal: ''}}
        ])
    }, [serialNr, relay])

    // const handleEdit = (index) => {
    //     const prevValue = relais[index].desc

    //     setRelais((prevRelais) =>
    //         prevRelais.map((item, i) => {
    //             if (i === index) {
    //                 return {
    //                     ...item,
    //                     desc: (
    //                         <input 
    //                             className='settings__page__item__input'
    //                             type="text" 
    //                             value={prevValue}
    //                             onChange={(e) => handleInputChange(e, i)}
    //                         />
    //                     ),
    //                     edit: { ...item.edit, active: true, prevVal: prevValue},
    //                 };
    //             }
    //             return item;
    //         })
    //     );
    // }

    // const handleCancel = (index) => {
    //     const prevValue = relais[index].edit.prevVal

    //     const cancelRelais = relais.map((item, i) => {
    //         if (i === index) {
    //           return { ...item,
    //             desc: prevValue,
    //             edit: { ...item.edit, active: false, prevVal: ''} };
    //         }
    //         return item;
    //     });
    //     setRelais(cancelRelais);
    // }

    // const handleSave = (index) => {
    //     const desiredValue = relais[index].desc.props.value
    //     if (desiredValue === "") {
    //         //show error cannot be empty
    //     } else {
    //         const saveRelais = relais.map((item, i) => {
    //             if (i === index) {
    //               return { ...item, 
    //                 desc: desiredValue,
    //                 edit: { ...item.edit, active: false, prevVal: ''} };
    //             }
    //             return item;
    //         });
    //         setRelais(saveRelais);
    //     }

        
    // }

    const handleInputChange = (e, index) => {
        setRelais((prevRelais) => 
            prevRelais.map((item, i) => {
                if (i === index) {
                    return {
                        ...item,
                        desc: 
                        <input 
                            className='settings__page__item__input'
                            type="text" 
                            value={e.target.value}
                            onChange={(e) => handleInputChange(e, i)}
                        />
                    }
                }
                return item;
            }
        ))
    }

    // const [pvNodes] = useState(["pvNode1", "pvNode2", "pvNode3"])

    useEffect(() => {
        let index = 0

        switch (relay.task.mode) {
            case "n_lowest":
                index = 2
                setModes((prevModes) =>
                    prevModes.map((mode, index) => {
                      if (index === 2) {
                        return {
                          ...mode,
                          val: relay.task.n ? relay.task.n : 1
                        };
                      }
                      return mode;
                    })
                );
                break;
            case "curtailment":
                index = 1
                setModes((prevModes) =>
                    prevModes.map((mode, index) => {
                      if (index === 2) {
                        return {
                          ...mode,
                          val: relay.task.pv_node_name
                        };
                      }
                      return mode;
                    })
                );
                break;
            case "manual":
                index = 4
                break;
            case "imbalance":
                index = 3
                setModes((prevModes) =>
                    prevModes.map((mode, index) => {
                      if (index === 2) {
                        return {
                          ...mode,
                          val: relay.task.user_id
                        };
                      }
                      return mode;
                    })
                );
                break;
            case "price_action":
                index = 5
                setModes((prevModes) =>
                    prevModes.map((mode, index) => {
                        if (index === 5) {
                        return {
                            ...mode,
                            val: relay.task.price
                        };
                        }
                        return mode;
                    })
                );
                setOperator(relay.task.operator)
                setOriginalOperator(relay.task.operator)
                break;
            default:
                index = 0
                break;
        }

        setSelectedMode(index);
        setOriginalSelectedMode(index);

        setOriginal(modes)

    }, [relay])

    const [modes, setModes] = useState([{name: 'Stand-By', val: 0}, {name: 'curtailment', val: ''}, {name: 'n_lowest', val: 1}, {name: 'imbalance', val: ''}, {name: 'manual', val: new Date().toISOString().slice(0, 16)}, {name: 'price-action', val: 0}])
    const [original, setOriginal] = useState([{name: 'Stand-By', val: 0}, {name: 'curtailment', val: ''}, {name: 'n_lowest', val: 1}, {name: 'imbalance', val: ''}, {name: 'manual', val: new Date().toISOString().slice(0, 16)}, {name: 'price-action', val: 0}])
 
    const [originalSelectedMode, setOriginalSelectedMode] = useState(0);
    const [selectedMode, setSelectedMode] = useState(0);

    const [changes, setChanges] = useState(false)
    
    const [standByHovered, setStandByHovered] = useState(false)
    const [curtailmentHovered, setCurtailmentHovered] = useState(false)
    const [lowestHovered, setLowestHovered] = useState(false);
    const [imbalanceHovered, setImbalanceHovered] = useState(false)
    const [manualHovered, setManualHovered] = useState(false)
    const [priceActionHovered, setPriceActionHovered] = useState(false)
    const [status, setStatus] = useState(false)
    const [operator, setOperator] = useState("<")
    const [originalOperator, setOriginalOperator] = useState("");

    useEffect(() => {
        //needs to be updated check only selected mode value
        if (selectedMode !== originalSelectedMode || modes[selectedMode].val != original[selectedMode].val || operator !== originalOperator) {
            setChanges(true)
        } else {
            setChanges(false)
        }
        

    }, [selectedMode, modes, operator]);

    const handleModeCancel = () => {
        setSelectedMode(originalSelectedMode);

        setModes((prevMode) => 
            prevMode.map((mode, i) => {
                return {
                    ...mode,
                    val: original[i].val
                }
            })
        )

        setChanges(false)
    }

    const handleModeSave = async() => {
    
        setOriginalSelectedMode(selectedMode);

        setOriginal((prevOriginal) => 
            prevOriginal.map((item, i) => {
                if (i === selectedMode) {
                    return {
                        ...item,
                        val: modes[i].val
                    }
                }
                return item;
            })
        )

        //send update to api
        const checkData = {
            gateway: relay.gateway,
            mode: modes[selectedMode].name == 'Stand-By' ? '': modes[selectedMode].name,
            n: parseInt(modes[2].val),
            name: relay.name,
            node_id: relay.node_id,
            pv_node_name: modes[1].val,
            relais_id: relay.id,
            user_id: modes[3].val,
            date: new Date(modes[4].val).toISOString(),
            status: Boolean(status),
            operator: operator,
            price: parseInt(modes[5].val)
        }

        const response = await settingsApi.updateRelais(serialNr, checkData);

        setChanges(false)
    }

    const handleModeValSwitch = (value, index) => {

        setModes((prevMode) => 
            prevMode.map((mode, i) => {
                if (i === index) {
                    return {
                        ...mode,
                        val: value
                    }
                }
                return mode
            })
        )

    }

    return (
        <div className="settings__page__items">
            {relais.map((item, i) => 
                <div className="settings__page__item">
                    <div className="settings__page__item__info">
                        <div className="settings__page__item__title">{item.title}</div>
                        <div className="settings__page__item__desc">{item.desc}</div>
                    </div>
                    {/* {!item.edit.active && <div className="settings__page__item__edit" onClick={() => handleEdit(i)}>Edit</div>}
                    {item.edit.active && 
                    <div className="settings__page__item__edit--editing">
                        <div className="settings__page__item__save" onClick={() => handleSave(i)}>Save</div>
                        <div className="settings__page__item__cancel" onClick={() => handleCancel(i)}>Cancel</div>
                    </div>    
                    } */}
                </div>
            )}
            <div className="settings__page__item">
                <div className="settings__page__item__info">
                    <div className="settings__page__item__title">Task</div>
                    <div className='settings__page__item__radio__selection'>
                        {/* Stand-By */}
                        <div className={darkMode? `settings__page__item__radio__card--darkmode ${selectedMode == 0 && 'selected'}` : `settings__page__item__radio__card--lightmode ${selectedMode == 0 && 'selected'}`} onClick={() => setSelectedMode(0)} onMouseEnter={() => setStandByHovered(true)} onMouseLeave={() => setStandByHovered(false)}>
                            <div className='settings__page__item__radio__card__content'>
                                <div className='settings__page__item__radio__header'>
                                    <div style={darkMode? (selectedMode == 0 ? {color:'black'} : {color:'white'})  : {color:'black'}}>Stand-By</div>
                                    <div className={`settings__page__item__radio__button ${standByHovered && selectedMode != 0 && 'hovered'} ${selectedMode == 0 && 'selected'}`}>{selectedMode == 0 && <div className='settings__page__item__radio__button__selected'></div>}</div>
                                </div>
                                <div className='settings__page__item__radio__desc'>Sets the relais into a stand-by mode where it waits on your input to function</div>
                            </div>
                        </div>
                        {/* curtailment */}
                        <div className={darkMode? `settings__page__item__radio__card--darkmode ${selectedMode == 1 && 'selected'}` : `settings__page__item__radio__card--lightmode ${selectedMode == 1 && 'selected'}`} onClick={() => setSelectedMode(1)} onMouseEnter={() => setCurtailmentHovered(true)} onMouseLeave={() => setCurtailmentHovered(false)}>
                            <div className='settings__page__item__radio__card__content'>
                                <div className='settings__page__item__radio__header'>
                                    <div style={darkMode? (selectedMode == 1 ? {color:'black'} : {color:'white'})  : {color:'black'}}>Curtailment</div>
                                    <div className={`settings__page__item__radio__button ${curtailmentHovered && selectedMode != 1 && 'hovered'} ${selectedMode == 1 && 'selected'}`}>{selectedMode == 1 && <div className='settings__page__item__radio__button__selected'></div>}</div>
                                </div>
                                <div className='settings__page__item__radio__desc'>Turns the solar panels off or on depending on certain situation</div>
                                <div style={{marginTop: 10}}>
                                    {/* <select className='settings__page__item__input' onChange={(e) => handleModeValSwitch(e.target.value, 1)} value={modes[1].val}>
                                        {pvNodes.map((profile, j) => (
                                            <option key={j} value={profile}>{profile}</option>
                                        ))}
                                    </select> */}
                                    <input className='settings__page__item__input' type="text" value={modes[1].val} onChange={(e) => handleModeValSwitch(e.target.value, 1)}/>
                                </div>
                            </div> 
                        </div>
                        {/* n_lowest */}
                        <div className={darkMode? `settings__page__item__radio__card--darkmode ${selectedMode == 2 && 'selected'}` : `settings__page__item__radio__card--lightmode ${selectedMode == 2 && 'selected'}`} onClick={() => setSelectedMode(2)} onMouseEnter={() => setLowestHovered(true)} onMouseLeave={() => setLowestHovered(false)}>
                            <div className='settings__page__item__radio__card__content'>
                                <div className='settings__page__item__radio__header'>
                                    <div style={darkMode? (selectedMode == 2 ? {color:'black'} : {color:'white'})  : {color:'black'}}>Lowest in hours</div>
                                    <div className={`settings__page__item__radio__button ${lowestHovered && selectedMode != 2 && 'hovered'} ${selectedMode == 2 && 'selected'}`}>{selectedMode == 2 && <div className='settings__page__item__radio__button__selected'></div>}</div>
                                </div>
                                <div className='settings__page__item__radio__desc'>Takes the lowest hours</div>
                                <div style={{marginTop: 10}}>
                                    <input style={{width: 200}} className='settings__page__item__input' type="number" min={1} value={modes[2].val} onChange={(e) => {e.target.value > 0 ? handleModeValSwitch(e.target.value, 2) : handleModeValSwitch(1, 2)}}/>
                                </div>
                            </div>
                        </div>
                        {/* imbalance */}
                        <div className={darkMode? `settings__page__item__radio__card--darkmode ${selectedMode == 3 && 'selected'}` : `settings__page__item__radio__card--lightmode ${selectedMode == 3 && 'selected'}`} onClick={() => setSelectedMode(3)} onMouseEnter={() => setImbalanceHovered(true)} onMouseLeave={() => setImbalanceHovered(false)}>
                            <div className='settings__page__item__radio__card__content'>
                                <div className='settings__page__item__radio__header'>
                                    <div style={darkMode? (selectedMode == 3 ? {color:'black'} : {color:'white'})  : {color:'black'}}>Imbalance</div>
                                    <div className={`settings__page__item__radio__button ${imbalanceHovered && selectedMode != 3 && 'hovered'} ${selectedMode == 3 && 'selected'}`}>{selectedMode == 3 && <div className='settings__page__item__radio__button__selected'></div>}</div>
                                </div>
                                <div className='settings__page__item__radio__desc'>Continous on yuso signal of user_id</div>
                                <div style={{marginTop: 10}}>
                                    <input className='settings__page__item__input' type="text" value={modes[3].val} onChange={(e) => handleModeValSwitch(e.target.value, 3)}/>
                                </div>
                            </div>
                        </div>
                        {/* Manual */}
                        <div className={darkMode? `settings__page__item__radio__card--darkmode ${selectedMode == 4 && 'selected'}` : `settings__page__item__radio__card--lightmode ${selectedMode == 4 && 'selected'}`} onClick={() => setSelectedMode(4)} onMouseEnter={() => setManualHovered(true)} onMouseLeave={() => setManualHovered(false)}>
                            <div className='settings__page__item__radio__card__content'>
                                <div className='settings__page__item__radio__header'>
                                    <div style={darkMode? (selectedMode == 4 ? {color:'black'} : {color:'white'})  : {color:'black'}}>Manual</div>
                                    <div className={`settings__page__item__radio__button ${manualHovered && selectedMode != 4 && 'hovered'} ${selectedMode == 4 && 'selected'}`}>{selectedMode == 4 && <div className='settings__page__item__radio__button__selected'></div>}</div>
                                </div>
                                <div className='settings__page__item__radio__desc'>Send a manual signal to the relais</div>
                                <div style={{marginTop: 10}}>
                                    <div className='settings__page__item__radio__desc'>Status: <input className='settings__page__item__input' type="checkbox" value={status} onChange={(e) => setStatus(e.target.checked)}/></div>
                                    <input style={{width: 220}} className='settings__page__item__input' type="datetime-local" value={modes[4].val} onChange={(e) => handleModeValSwitch(e.target.value, 4)}/>
                                </div>
                            </div>
                        </div>
                        {/* Price Action */}
                        <div className={darkMode? `settings__page__item__radio__card--darkmode ${selectedMode == 5 && 'selected'}` : `settings__page__item__radio__card--lightmode ${selectedMode == 5 && 'selected'}`} onClick={() => setSelectedMode(5)} onMouseEnter={() => setPriceActionHovered(true)} onMouseLeave={() => setPriceActionHovered(false)}>
                            <div className='settings__page__item__radio__card__content'>
                                <div className='settings__page__item__radio__header'>
                                    <div style={darkMode? (selectedMode == 5 ? {color:'black'} : {color:'white'})  : {color:'black'}}>Price Action</div>
                                    <div className={`settings__page__item__radio__button ${priceActionHovered && selectedMode != 5 && 'hovered'} ${selectedMode == 5 && 'selected'}`}>{selectedMode == 5 && <div className='settings__page__item__radio__button__selected'></div>}</div>
                                </div>
                                <div className='settings__page__item__radio__desc'>belpex prijs boven of onder een bepaalde waarde komt</div>
                                <div style={{marginTop: 10}}>
                                    <div className='settings__page__item__radio__desc'>Operator: <select value={operator} onChange={(e) => setOperator(e.target.value)} className='settings__page__item__input' name="Operators"> <option value="<">{"< Less than"}</option> <option value=">">{"> Greater than"}</option> </select> </div>
                                    <input style={{width: 100}} className='settings__page__item__input' type="number" value={modes[5].val} onChange={(e) => {handleModeValSwitch(e.target.value, 5)}}/> EUR/MWh
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {changes && 
                <div className="settings__page__item__edit--editing" style={{marginTop: 10, justifyContent: 'flex-end'}}>
                    <div className="settings__page__item__save" onClick={() => handleModeSave()}>Save Changes</div>
                    <div className="settings__page__item__cancel" onClick={() => handleModeCancel()}>Cancel</div>
                </div> 
            }
        </div>
    )
}

export default SettingsRelaisItem
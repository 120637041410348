import React, { useContext, useEffect, useState } from "react";
import User from "../../../api/user";
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext';
import axios from "axios";

const InstallationList = ({ role, user }) => {
    const { login } = useContext(UserContext);
    const userApi = User();

    const [installations, setInstallations] = useState(null);
    const [installerView, setInstallerView] = useState(null);
    const [filteredRole, setFilteredRole] = useState("");
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

    const navigate = useNavigate();

    const fetchInstallations = async (role, installerMock) => {
        let installationsresponse;

        if (role === "Installer") {
            const domain = process.env.REACT_APP_AUTH0_DOMAIN;
            const token = await userApi.getManagementApiToken();

            let metaData = "";
            if (!installerMock) {
                const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
                const metadataResponse = await axios.get(userDetailsByIdUrl, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                metaData = metadataResponse.data.app_metadata;
            } else {
                metaData = installerMock.app_metadata;
            }

            installationsresponse = await userApi.getUsersBySerialNr(metaData.clients);

            if (metaData.serial_Nr) {
                const filteredInstallations = installationsresponse.filter(installation => {
                    if (installation.app_metadata.serial_Nr === metaData.serial_Nr) {
                        installation.app_metadata.role = "User";
                    }

                    return installation;
                });
                setInstallations(filteredInstallations);
            } else {
                setInstallations(installationsresponse);
            }
        } else if (role === "Admin" || role === "SuperAdmin" || role === "Sales") {
            try {
                const userResponse = await userApi.getAllUsersInDatabase();
                installationsresponse = userResponse;

                if (filteredRole) {
                    installationsresponse = installationsresponse.filter(installation =>
                        installation.app_metadata?.role === filteredRole
                    );
                }

                setInstallations(installationsresponse);
            } catch (error) {
                console.log(error);
                setInstallations([]);
            }
        }
    };

    useEffect(() => {
        fetchInstallations(role, null);
    }, [role]); // Fetch installations based on role

    useEffect(() => {
        fetchInstallations(role, null);
    }, [filteredRole]); // Fetch installations based on filtered role

    const handleRoleFilterChange = (event) => {
        setFilteredRole(event.target.value);
    };

    const returnToAdminDashboard = () => {
        setInstallerView(null);
        fetchInstallations(role, null);
    };

    const navigateToUserDashboard = (user) => {
        if (user) {
            login(user);
            navigate("/HomePage");
        } else {
            console.log("Something went wrong. Try again later.");
        }
    };

    const navigateToInstallerDashboard = (installer) => {
        if (installer) {
            setInstallerView(installer);
            fetchInstallations("Installer", installer);
        } else {
            console.log("Something went wrong. Try again later.");
        }
    };

    // Sorting function
    const sortData = (key) => {
        let direction = 'asc';

        // Set 'logins_count' to be initially sorted in descending order
        if (key === 'logins_count' && sortConfig.key !== 'logins_count') {
            direction = 'desc';
        } else if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }

        setSortConfig({ key, direction });
    };

    const sortedInstallations = installations ? [...installations].sort((a, b) => {
        let aKey = a[sortConfig.key];
        let bKey = b[sortConfig.key];

        // Handle nested role property specifically
        if (sortConfig.key === 'role') {
            aKey = a.app_metadata?.role || 'No Role';
            bKey = b.app_metadata?.role || 'No Role';
        }

        if (aKey < bKey) {
            return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (aKey > bKey) {
            return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
    }) : [];

    return (
        <div>
            <div className="adminDashboard__filter">
                <label htmlFor="roleFilter">Filter volgens Role:</label>
                <select id="roleFilter" onChange={handleRoleFilterChange} value={filteredRole}>
                    <option value="">Alle Rolen</option>
                    <option value="Admin">Admin</option>
                    <option value="Installer">Installer</option>
                    <option value="Sales">Sales</option>
                    <option value="User">User</option>
                </select>
            </div>
            <table className="adminDashboard__table-user">
                <thead>
                    {installerView && (
                        <tr>
                            <th colSpan={2}>Current view of installer: {installerView.email}</th>
                            <th colSpan={3}>
                                <button className="adminDashboard__table__btn adminDashboard__table__installerdashboard__btn" onClick={returnToAdminDashboard}>
                                    Return to Admin View
                                </button>
                            </th>
                        </tr>
                    )}
                    <tr>
                        <th>Picture</th> {/* No sorting on this column */}
                        <th
                            onClick={() => sortData('email')}
                            className={`${sortConfig.key === 'email' ? 'sorted-column' : ''
                                } ${sortConfig.key === 'email' && sortConfig.direction === 'asc' ? 'sorted-asc' : sortConfig.key === 'email' && sortConfig.direction === 'desc' ? 'sorted-desc' : ''}`}
                        >
                            Email
                        </th>
                        <th
                            onClick={() => sortData('role')}
                            className={`${sortConfig.key === 'role' ? 'sorted-column' : ''
                                } ${sortConfig.key === 'role' && sortConfig.direction === 'asc' ? 'sorted-asc' : sortConfig.key === 'role' && sortConfig.direction === 'desc' ? 'sorted-desc' : ''}`}
                        >
                            Role
                        </th>
                        <th
                            onClick={() => sortData('logins_count')}
                            className={`${sortConfig.key === 'logins_count' ? 'sorted-column' : ''
                                } ${sortConfig.key === 'logins_count' && sortConfig.direction === 'asc' ? 'sorted-asc' : sortConfig.key === 'logins_count' && sortConfig.direction === 'desc' ? 'sorted-desc' : ''}`}
                        >
                            Login Amount
                        </th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedInstallations.length > 0 ? sortedInstallations.map((installation, index) => (
                        <tr key={index}>
                            <td><img src={installation.picture} width={30} style={{ borderRadius: 10 }} /></td>
                            <td>{installation.email}</td>
                            <td>{installation.app_metadata?.role || "No Role"}</td>
                            <td>{installation.logins_count}</td>
                            {installation.app_metadata?.role === 'User' ? (
                                <td><button onClick={() => navigateToUserDashboard(installation)} className="adminDashboard__table__btn adminDashboard__table__userdashboard__btn">Go to User Dashboard</button></td>
                            ) : installation.app_metadata?.clients && installation.app_metadata?.role === "Installer" ? (
                                <td><button onClick={() => navigateToInstallerDashboard(installation)} className="adminDashboard__table__btn adminDashboard__table__installerdashboard__btn">Go to Installer Dashboard</button></td>
                            ) : (
                                <td><button className="adminDashboard__table__btn adminDashboard__table__button-disabled" disabled>No Action Available</button></td>
                            )}
                        </tr>
                    )) : (
                        <tr className='adminDashboard__error__not__found--lightmode'>
                            <td style={{ textAlign: 'center', color: '#c62828' }} colSpan={5}>None found</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default InstallationList;

import './homePage.css'
import HomePageNews from './HomePage-Components/homePageNews'
import HomePageSectoren from './HomePage-Components/homePageSectoren'
import HomePageOurSolutions from './HomePage-Components/homePageOurSolutions'

const HomePage = () => {
    return (
        <div className='home__page__container'>
            <HomePageNews />
            <HomePageOurSolutions />
            <HomePageSectoren />
        </div>
    )
}

export default HomePage
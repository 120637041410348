import "./dashboardNavbarModeSlider.css"
import Moon from '../../../../Images/icons8-moon-100.png'
import Sun from '../../../../Images/icons8-sun-100.png'
import React, { useEffect, useState, useContext } from 'react'
import { DarkModeContext } from "../../../../context/DarkModeContext"


const DashboardNavbarModeSlider = () => {

    const {darkMode, toggleDarkMode} = useContext(DarkModeContext);

    const handleClick = () => {
        toggleDarkMode(!darkMode);
    };

    return (
        <div className="Dashboard-Navbar-Adjustables-DarkMode-Slider-Container">
            <img
                className={`moon ${darkMode ? 'moved' : ''}`}
                src={Moon}
                alt="Moon"
                onClick={handleClick}
            />
            <img
                className={`sun ${darkMode ? 'moved' : ''}`}
                src={Sun}
                alt="sun"
                onClick={handleClick}
            />
            <div onClick={handleClick} className={`Dashboard-Navbar-Adjustables-DarkMode-Slider ${darkMode ? 'dark' : 'light'}`}></div>
        </div>
    )

}


export default DashboardNavbarModeSlider;
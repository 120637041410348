import React from 'react';
import './batteryTower.css';

import batteryTower from '../../../../Images/batteryStack.png';
import installedTower from '../../../../Images/installedTower.jpg';
import singleBattery from '../../../../Images/singleBattery.png';
import outdoorCabinet from '../../../../Images/outdoorCabinet.png';

const BatteryTower = () => {
    return (
        <div className="batteryPage__container">
            <section className="batteryPage__introduction">
                <h1>FIRN-BAT5100 Batterij</h1>
                <div className="batteryPage__intro-content">
                    <img src={singleBattery} alt="Batterij uit de toren" className="batteryPage__single-battery" />
                    <p>
                        De FIRN-BAT5100 is ontworpen om energie-opslag efficiënter te maken. Met een slimme integratie van energiebeheer en dynamische elektriciteitstarieven biedt het de perfecte oplossing voor particulieren en bedrijven.
                    </p>
                </div>
            </section>

            <section className="batteryPage__tower">
                <div className="batteryPage__tower-content">
                    <div className="batteryPage__tower-text">
                        <h2>De FIRN Batterij Toren</h2>
                        <p>
                            De FIRN Batterij Toren biedt een innovatieve oplossing voor grootschalige energieopslag. Het systeem maakt gebruik van onze FIRN-BAT5100 batterijen om energie te verzamelen en op te slaan die later kan worden gebruikt wanneer de vraag het hoogst is, of wanneer energie van het net duurder is. Dit zorgt niet alleen voor een verlaging van de energiekosten, maar ook voor een verhoogde onafhankelijkheid van het elektriciteitsnet.
                        </p>
                        <p>
                            De batterij toren is uitgerust met omgevingskoeling, wat betekent dat de batterijen altijd veilig en efficiënt blijven functioneren, zelfs bij intensief gebruik. Daarnaast is de installatie flexibel, met de mogelijkheid voor zowel kast- als wandmontage, zodat het systeem kan worden aangepast aan de beschikbare ruimte en specifieke behoeften van de gebruiker.
                        </p>
                        <h3>Waarom Kiezen Voor de FIRN Batterij Toren?</h3>
                        <ul>
                            <li><strong>Opslag van Hernieuwbare Energie:</strong> De FIRN Batterij Toren is ideaal om overtollige energie van zonnepanelen en windturbines op te slaan.</li>
                            <li><strong>Onafhankelijkheid van het Net:</strong> Door de opgeslagen energie te gebruiken tijdens piekuren, vermindert u uw afhankelijkheid van het elektriciteitsnet.</li>
                            <li><strong>Geavanceerd Energiebeheer:</strong> In combinatie met het FIRN energiebeheersysteem zorgt de batterij toren ervoor dat de energie altijd efficiënt wordt ingezet.</li>
                            <li><strong>Schaling voor Industrie:</strong> De Batterij Toren kan worden opgeschaald afhankelijk van de energiebehoeften.</li>
                        </ul>
                    </div>
                    <div className="batteryPage__tower-image">
                        <img src={batteryTower} alt="Batterij Toren" />
                    </div>
                </div>
            </section>

            <div className="batteryPage__installation-and-cabinet">
                <div className="batteryPage__installation-content">
                    <section className="batteryPage__installed-tower">
                        <h2>Installatie bij een Klant</h2>
                        <div className="batteryPage__installed-content">
                            <img src={installedTower} alt="Batterij Toren bij een klant" className="installed-tower-image" />
                            <p>
                                Hier zie je een FIRN Batterij Toren die is geïnstalleerd bij een klant, waarmee zij hun energiebeheer optimaliseren en hun afhankelijkheid van het elektriciteitsnet verminderen.
                            </p>
                        </div>
                    </section>

                    <section className="batteryPage__cabinet">
                        <h2>Optionele Buitenbehuizing voor de Batterij Toren</h2>
                        <div className="batteryPage__cabinet-content">
                            <p>
                                Naast de geavanceerde batterijopslag biedt FIRN ook een robuuste IP55-behuizing aan voor de installatie van de batterijen. Deze buitenbehuizing is speciaal ontworpen voor zowel binnen- als buitenopstellingen en zorgt voor extra bescherming van de batterijen tegen stof, water en temperatuurverschillen.
                            </p>
                            <ul>
                                <li>IP55-gecertificeerde bescherming tegen weersinvloeden</li>
                                <li>Airconditioning voor optimale koeling</li>
                                <li>Kan tot 20 batterijen en een omvormer bevatten</li>
                            </ul>
                            <p>Met deze buitenbehuizing blijft je energiesysteem optimaal presteren, ongeacht de omstandigheden.</p>
                        </div>
                    </section>
                </div>

                <div className="batteryPage__cabinet-image">
                    <img src={outdoorCabinet} alt="Outdoor Cabinet" />
                </div>
            </div>
        </div>
    );
}

export default BatteryTower;

import React, { useEffect, useMemo } from 'react'
import { formatNumber, formatCurrency } from '../../../utils/helpers'
import foto1 from '../../../assets/images/Bijlage1-foto1.png'
import foto2 from '../../../assets/images/Bijlage1-foto2.png'
import foto3 from '../../../assets/images/Bijlage1-foto3.png'

const eigenVerbruikRaming = {
    4: 40,
    2: 35,
    1: 30,
    0.5: 15,
    0.25: 10,
};

const calculateRoundedRatio = (ratio) => {
    if (ratio < 0.4) return 0.25;
    if (ratio <= 0.75) return 0.5;
    if (ratio < 1.5) return 1;
    if (ratio <= 2.75) return 2;
    return 4;
};

const Bijlage1 = ({
    klant, batterij, producten, prices, premies,
    updateTabelData, systemsVerhoudingen, updateBerekeningsData,
    averagePrice, averageLowPrice, averageHighPrice, totaleOmvormcapaciteit,
    setLageAankoopDA, setHogeVerkoopDA
}) => {
    const verbruik = klant.verbruik / 1000;
    const pv = klant.productie / 1000;
    const eigenVerbruikPercentage = klant.productie !== 0 ? ((klant.productie - klant.injectie) / klant.productie) * 100 : 0
    const verhoudingBatterijPV = pv === 0 ? 0 : batterij / pv;
    const verhoudingBatPVAfgerond = calculateRoundedRatio(verhoudingBatterijPV);

    const ramingExtraEV = eigenVerbruikRaming[verhoudingBatPVAfgerond] || 0;
    const verhoudingVerbruikPV = verbruik / pv;
    const verhoudingVerbruikPVAfgerond = Math.max(0.25, Math.min(2, Math.ceil(verhoudingVerbruikPV * 100) / 100));

    const extraVerbruik = useMemo(() => {
        if (verhoudingVerbruikPVAfgerond < 1) {
            return ramingExtraEV - ramingExtraEV * (1 - verhoudingVerbruikPVAfgerond) * 0.4;
        } else if (verhoudingVerbruikPVAfgerond > 1) {
            return ramingExtraEV - ramingExtraEV * (verhoudingVerbruikPVAfgerond - 1) * 0.2;
        }
        return ramingExtraEV;
    }, [ramingExtraEV, verhoudingVerbruikPVAfgerond]);

    const totaleKost = klant.verbruik * klant.aankoop;
    const opbrengstInjectie = klant.injectie * klant.verkoop;
    const totaleNettoKost = totaleKost - opbrengstInjectie;
    const totaleKostNa15Jaar = totaleNettoKost * 15;
    const totaalEigenVerbruik = Math.min(100, eigenVerbruikPercentage + extraVerbruik);
    const ramingVerschil = totaalEigenVerbruik - eigenVerbruikPercentage;
    const minderAanTeKopen = Math.min(klant.verbruik, klant.productie * ramingVerschil / 100);
    const voordeelEV = minderAanTeKopen * klant.aankoop - minderAanTeKopen * klant.verkoop

    const kostYuso = producten.data.kostYuso.value
    const gemL4Aankoop = averageLowPrice + kostYuso
    const gemAankoop = averagePrice + kostYuso
    const dagverbruik = klant.verbruik === 0 ? 0 : klant.verbruik / 365;
    const laagsteTariefAankoop = (batterij / dagverbruik > 1) ? 1 : Math.max(0, batterij / dagverbruik);
    const gemTariefAankoop = 1 - laagsteTariefAankoop
    useEffect(() => {
        setLageAankoopDA(laagsteTariefAankoop * gemL4Aankoop + gemTariefAankoop * gemAankoop);
    }, [laagsteTariefAankoop, gemL4Aankoop, gemTariefAankoop, gemAankoop, setLageAankoopDA]);
    const ramingLagereAankoop = klant.aankoopExcl - (laagsteTariefAankoop * gemL4Aankoop + gemTariefAankoop * gemAankoop)
    const verbruikAankoop = ramingLagereAankoop === 0 ? 0 : klant.verbruik - minderAanTeKopen;
    const voordeelAankoop = verbruikAankoop === 0 ? 0 : verbruikAankoop * ramingLagereAankoop;

    const gemH4Verkoop = averageHighPrice - kostYuso
    const gemVerkoop = averagePrice - kostYuso
    const restInjectie = klant.injectie - minderAanTeKopen
    const dagverbruik180 = restInjectie / 180
    const laagsteTariefVerkoop = (klant.injectie === 0 || pv === 0) ? 0 : (batterij / dagverbruik180 > 1) ? 1 : Math.max(0, batterij / dagverbruik180);
    const gemTariefVerkoop = 1 - laagsteTariefVerkoop
    useEffect(() => {
        setHogeVerkoopDA(laagsteTariefVerkoop * gemH4Verkoop + gemTariefVerkoop * gemVerkoop);
    }, [laagsteTariefVerkoop, gemH4Verkoop, gemTariefVerkoop, gemVerkoop, setHogeVerkoopDA]);
    const ramingHogereVerkoop = (laagsteTariefVerkoop * gemH4Verkoop + gemTariefVerkoop * gemVerkoop) - klant.verkoop
    const voordeelVerkoop = ramingHogereVerkoop * restInjectie

    const roundRatio = (ratio) => {
        const thresholds = [
            { limit: 9.5, value: 10 },
            { limit: 8.5, value: 9 },
            { limit: 7.5, value: 8 },
            { limit: 6.5, value: 7 },
            { limit: 5.5, value: 6 },
            { limit: 4.5, value: 5 },
            { limit: 3.5, value: 4 },
            { limit: 2.5, value: 3 },
            { limit: 1.5, value: 2 },
            { limit: 1, value: 1 },
        ];

        for (const threshold of thresholds) {
            if (ratio >= threshold.limit) {
                return threshold.value;
            }
        }
        return parseFloat(ratio.toFixed(1));
    };

    const getPercentage = (ratio) => {
        switch (ratio) {
            case 1 / 4: return 0.83;
            case 1 / 3: return 0.91;
            case 0: return 0;
            default: return 1;
        }
    };

    const verhoudingProductieVerbruik = klant.verbruik === 0 ? 0 : klant.productie / klant.verbruik;
    const verhoudingProductieVerbruikAfgerond = roundRatio(verhoudingProductieVerbruik);

    let onbPrijsBatterij = {}
    for (let i = 10; i > 0; i--) {
        let val = i / 10
        let prev = (i + 1) / 10
        if (i === 10) {
            onbPrijsBatterij[val] = producten.data.onbalansBatterij.value
        } else {
            onbPrijsBatterij[val] = (onbPrijsBatterij[prev] - ((producten.data.onbalansBatterij.value - producten.data.onbalansGeenPV.value) / 10))
        }
    }

    let onbPrijsHoogPV = {}
    for (let i = 10; i > 1; i--) {
        let val = i
        let prev = (i + 1)
        if (i === 10) {
            onbPrijsHoogPV[val] = producten.data.onbalansPV.value
        } else {
            onbPrijsHoogPV[val] = (onbPrijsHoogPV[prev] - ((producten.data.onbalansPV.value - producten.data.onbalansBatterij.value) / 9))
        }
    }
    onbPrijsHoogPV[1] = producten.data.onbalansBatterij.value

    let prijsOnbalans1 = producten.data.onbalansGeenPV.value;
    if (verhoudingProductieVerbruik > 0) {
        if (verhoudingProductieVerbruik <= 1) {
            prijsOnbalans1 = onbPrijsBatterij[verhoudingProductieVerbruikAfgerond];
        } else {
            prijsOnbalans1 = onbPrijsHoogPV[verhoudingProductieVerbruikAfgerond];
        }
    }
    let prijsOnbalans2 = producten.data.onbalansGeenPV.value;
    if (verhoudingProductieVerbruik > 0) {
        if (verhoudingProductieVerbruik <= 1) {
            prijsOnbalans2 = onbPrijsBatterij[verhoudingProductieVerbruikAfgerond];
        } else {
            prijsOnbalans2 = onbPrijsHoogPV[verhoudingProductieVerbruikAfgerond];
        }
    }
    let prijsOnbalans3 = producten.data.onbalansGeenPV.value;
    if (verhoudingProductieVerbruik > 0) {
        if (verhoudingProductieVerbruik <= 1) {
            prijsOnbalans3 = onbPrijsBatterij[verhoudingProductieVerbruikAfgerond];
        } else {
            prijsOnbalans3 = onbPrijsHoogPV[verhoudingProductieVerbruikAfgerond];
        }
    }

    prijsOnbalans1 *= getPercentage(systemsVerhoudingen[0]);
    prijsOnbalans2 *= getPercentage(systemsVerhoudingen[1]);
    prijsOnbalans3 *= getPercentage(systemsVerhoudingen[2]);

    const totaalPrijsOnbalans = prijsOnbalans1 + prijsOnbalans2 + prijsOnbalans3;
    const simOnbalansFactor = klant.simOnbalans / 100;
    const voordeelOnbalansPerKwh = totaalPrijsOnbalans * simOnbalansFactor;
    const voordeelOnbalans = klant.aansluitvermogen < totaleOmvormcapaciteit
        ? klant.aansluitvermogen * 2 * voordeelOnbalansPerKwh
        : voordeelOnbalansPerKwh * batterij;

    const voordeelTotaal = voordeelEV + voordeelVerkoop + voordeelAankoop + voordeelOnbalans;

    const ecoValue = premies?.eco?.options?.[klant?.eco]?.value || 0;
    const vlifValue = premies?.vlif?.options?.[klant?.vlif]?.value || 0;
    const investValue = premies?.invest?.options?.[klant?.invest]?.value || 0;
    const eco = prices.totaalPrijsPremies * ecoValue / 100;
    const vlif = prices.totaalPrijsPremies * vlifValue / 100;
    const invest = prices.totaalExclBtw * 0.25 * investValue / 100;
    const premie = eco + vlif + invest;

    useEffect(() => {
        updateTabelData({
            huidigeKost: totaleNettoKost,
            voordeelEV: -voordeelEV,
            voordeelDA: -voordeelVerkoop - voordeelAankoop,
            voordeelOnbalans: -voordeelOnbalans,
            voordeelTotaal: -voordeelTotaal,
            eco: eco,
            vlif: vlif,
            invest: invest,
            premie: premie
        });

        updateBerekeningsData({
            voorspellingEV: ramingVerschil,
            onbalansPerkWh: totaalPrijsOnbalans,
            onbalans: voordeelOnbalans
        })
    }, [totaleNettoKost, voordeelEV, voordeelVerkoop, voordeelAankoop, voordeelOnbalans, updateTabelData, voordeelTotaal, eco, vlif, invest, premie, ramingVerschil, totaalPrijsOnbalans, updateBerekeningsData]);

    return (<>
        <div className='offerte__page'>
            <h2 className='offerte__header'>Offerte Bijlage 1</h2>
            <div className='offerte__segment offerte__segment--inverted'>
                <h3 className='offerte__header'>Huidige situatie</h3>
            </div>
            <table className='offerte__table offerte__table--celled'>
                <thead className='offerte__table__thead'>
                    <tr className='offerte__table__row'>
                        <th className='offerte__table__header offerte__table__header--eight-wide'>Energieverbruik</th>
                        <th className='offerte__table__header'>Gemiddelde Energiekost</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className='offerte__table__row'>
                        <td className='offerte__table__cell'>{formatNumber(klant.verbruik)} kWh/jaar</td>
                        <td className='offerte__table__cell'>{formatCurrency(klant.aankoop)} /kWh</td>
                    </tr>
                </tbody>
            </table>
            <table className='offerte__table offerte__table--celled offerte__table--definition'>
                <tbody>
                    <tr className='offerte__table__row'>
                        <td className='offerte__table__cell offerte__table__cell--eight-wide'>Totale Kost Elektriciteit</td>
                        <td className='offerte__table__cell'>{formatCurrency(totaleKost)} /jaar</td>
                    </tr>
                    <tr className='offerte__table__row'>
                        <td className='offerte__table__cell'>Opbrengst Injectie PV</td>
                        <td className='offerte__table__cell'>{formatCurrency(opbrengstInjectie)} /jaar</td>
                    </tr>
                    <tr className='offerte__table__row'>
                        <td className='offerte__table__cell'>Totale Kost</td>
                        <td className='offerte__table__cell'>{formatCurrency(totaleNettoKost)} /jaar</td>
                    </tr>
                    <tr className='offerte__table__row'>
                        <td className='offerte__table__cell'>Eigen Verbruik</td>
                        <td className='offerte__table__cell'>{eigenVerbruikPercentage.toFixed(1)}%</td>
                    </tr>
                    <tr className='offerte__table__row'>
                        <td className='offerte__table__cell'>Totale Kost Na 15 jaar (zonder indexatie)</td>
                        <td className='offerte__table__cell'>{formatCurrency(totaleKostNa15Jaar)}</td>
                    </tr>
                </tbody>
            </table>
            <div className='offerte__segment offerte__segment--inverted'>
                <h3 className='offerte__header'>VOORDEEL 1</h3>
                <h4 className='offerte__header'>Extra eigen verbruik standaard werking "domme" batterij</h4>
            </div>
            <h5 className='offerte__header'>RAMING EXTRA EIGEN VERBRUIK</h5>
            <table className='offerte__table offerte__table--celled'>
                <tbody>
                    <tr className='offerte__table__row'>
                        <td className='offerte__table__cell'> Totaal Eigen Verbruik</td>
                        <td className='offerte__table__cell'>{(totaalEigenVerbruik).toFixed(1)}%</td>
                        <td className='offerte__table__cell'><b>Raming verschil</b></td>
                    </tr>
                    <tr className='offerte__table__row'>
                        <td className='offerte__table__cell'>In plaats van</td>
                        <td className='offerte__table__cell'>{eigenVerbruikPercentage.toFixed(1)}%</td>
                        <td className='offerte__table__cell'>{ramingVerschil.toFixed(1)}%</td>
                    </tr>
                </tbody>
            </table>
            <table className='offerte__table offerte__table--celled'>
                <tbody>
                    <tr className='offerte__table__row'>
                        <td className='offerte__table__cell offerte__table__cell--four-wide'><b>{formatNumber(minderAanTeKopen)} kWh</b></td>
                        <td className='offerte__table__cell'>Minder aan te kopen/verkopen</td>
                    </tr>
                    <tr className='offerte__table__row'>
                        <td className='offerte__table__cell'>{formatCurrency(minderAanTeKopen * klant.aankoop)}</td>
                        <td className='offerte__table__cell'>Opbrengst minder aankoop</td>
                    </tr>
                    <tr className='offerte__table__row'>
                        <td className='offerte__table__cell'>{formatCurrency(-(minderAanTeKopen * klant.verkoop))}</td>
                        <td className='offerte__table__cell'>Minder opbrengst verkoop</td>
                    </tr>
                    <tr className='offerte__table__row'>
                        <td className='offerte__table__cell'><b>{formatCurrency(voordeelEV)}</b></td>
                        <td className='offerte__table__cell'>Jaarlijkse winst eigen verbruik</td>
                    </tr>
                </tbody>
            </table>
            <table className='offerte__table offerte__table--green offerte__table--inverted'>
                <tbody>
                    <tr className='offerte__table__row'>
                        <td className='offerte__table__cell offerte__table__cell--four-wide'><b>{formatCurrency(voordeelEV * 15)}</b></td>
                        <td className='offerte__table__cell'>Opbrengst op 15 jaar</td>
                    </tr>
                </tbody>
            </table>
            <img src={foto1} className='offerte__image offerte__image--centered' alt='' />
            <div data-html2canvas-ignore='true' className='offerte__divider pageBreak'></div>
        </div>
        <div className='offerte__page'>
            <div className='offerte__segment offerte__segment--inverted'>
                <h3 className='offerte__header'>VOORDEEL 2</h3>
                <h4 className='offerte__header'>Day Ahead Prijzen</h4>
                <h5 className='offerte__header'>Aankoop bij lage uurprijs / Verkoop bij hoge uurprijs</h5>
            </div>
            <h5 className='offerte__header'>ENERGIE STORAGE ER BIJ - SMART EMS DAY AHEAD AANKOOP / ONBALANS</h5>
            <table className='offerte__table'>
                <thead className='offerte__table__thead'>
                    <tr className='offerte__table__row'>
                        <th className='offerte__table__header' colSpan={3}>Automatische aankoop bij lage energieprijzen - Day Ahead</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className='offerte__table__row'>
                        <td className='offerte__table__cell offerte__table__cell--eight-wide'>Raming lagere aankoopprijs</td>
                        <td className='offerte__table__cell offerte__table__cell--four-wide'>{formatCurrency(ramingLagereAankoop, 3)}</td>
                        <td className='offerte__table__cell'>/kWh</td>
                    </tr>
                    <tr className='offerte__table__row'>
                        <td className='offerte__table__cell'>Verbruik</td>
                        <td className='offerte__table__cell'>{formatNumber(verbruikAankoop)}</td>
                        <td className='offerte__table__cell'>kWh</td>
                    </tr>
                    <tr className='offerte__table__row'>
                        <td className='offerte__table__cell'>Voordeel in kost aankoop</td>
                        <td className='offerte__table__cell'>{formatCurrency(voordeelAankoop)}</td>
                        <td className='offerte__table__cell'>per jaar</td>
                    </tr>
                </tbody>
            </table>
            <table className='offerte__table offerte__table--green offerte__table--inverted'>
                <tbody>
                    <tr className='offerte__table__row'>
                        <td className='offerte__table__cell offerte__table__cell--eight-wide'>Opbrengst op 15 jaar</td>
                        <td className='offerte__table__cell offerte__table__cell--four-wide'>{formatCurrency(voordeelAankoop * 15)}</td>
                        <td className='offerte__table__cell'></td>
                    </tr>
                </tbody>
            </table>
            <table className='offerte__table'>
                <thead className='offerte__table__thead'>
                    <tr className='offerte__table__row'>
                        <th className='offerte__table__header' colSpan={3}>Automatische verkoop bij hoge energieprijzen - Day Ahead</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className='offerte__table__row'>
                        <td className='offerte__table__cell offerte__table__cell--eight-wide'>Raming extra voordeel verkoopprijs</td>
                        <td className='offerte__table__cell offerte__table__cell--four-wide'>{formatCurrency(ramingHogereVerkoop, 3)}</td>
                        <td className='offerte__table__cell'>/kWh</td>
                    </tr>
                    <tr className='offerte__table__row'>
                        <td className='offerte__table__cell'>Rest Injectie</td>
                        <td className='offerte__table__cell'>{formatNumber(restInjectie)}</td>
                        <td className='offerte__table__cell'>kWh</td>
                    </tr>
                    <tr className='offerte__table__row'>
                        <td className='offerte__table__cell'>Voordeel in kost verkoop</td>
                        <td className='offerte__table__cell'>{formatCurrency(voordeelVerkoop)}</td>
                        <td className='offerte__table__cell'>per jaar</td>
                    </tr>
                </tbody>
            </table>
            <table className='offerte__table offerte__table--green offerte__table--inverted'>
                <tbody>
                    <tr className='offerte__table__row'>
                        <td className='offerte__table__cell offerte__table__cell--eight-wide'>Opbrengst op 15 jaar</td>
                        <td className='offerte__table__cell offerte__table__cell--four-wide'>{formatCurrency(voordeelVerkoop * 15)}</td>
                        <td className='offerte__table__cell'></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div data-html2canvas-ignore="true" className="offerte__divider pageBreak"></div>
        <div className='offerte__page'>
            <div className='offerte__segment offerte__segment--inverted'>
                <h3 className='offerte__header'>VOORDEEL 3</h3>
                <h4 className='offerte__header'>Onbalansvergoeding</h4>
                <h5 className='offerte__header'>Hoge vergoeding bij tekorten of overschot op het net voor gebruik van de opslagcapaciteit</h5>
            </div>
            <h5 className='offerte__header'>ONBALANS TRADING (RAMING - KOSTEN)</h5>
            <table className='offerte__table'>
                <thead className='offerte__table__thead'>
                    <tr className='offerte__table__row'>
                        <th className='offerte__table__header' colSpan={3}>Meer volume - hogere transportkost / maar ruim gecompenseerd door opbrengsten</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className='offerte__table__row'>
                        <td className='offerte__table__cell offerte__table__cell--eight-wide'>Raming opbrengst op onbalans prijzen</td>
                        <td className='offerte__table__cell offerte__table__cell--four-wide'>{formatCurrency(voordeelOnbalans)}</td>
                        <td className='offerte__table__cell'>per jaar</td>
                    </tr>
                </tbody>
            </table>
            <table className='offerte__table offerte__table--green offerte__table--inverted'>
                <tbody>
                    <tr className='offerte__table__row'>
                        <td className='offerte__table__cell offerte__table__cell--eight-wide'>Opbrengst op 15 jaar</td>
                        <td className='offerte__table__cell offerte__table__cell--four-wide'>{formatCurrency(voordeelOnbalans * 15)}</td>
                        <td className='offerte__table__cell'></td>
                    </tr>
                </tbody>
            </table>
            <div className='offerte__segment'>Day Ahead</div>
            <img src={foto2} className='offerte__image offerte__image--centered' alt='' />
            <div className='offerte__segment'>Onbalans</div>
            <img src={foto3} className='offerte__image offerte__image--centered' alt='' />
        </div>
        <div data-html2canvas-ignore="true" className="offerte__divider pageBreak">
        </div >
    </>)
}

export default Bijlage1
import React from 'react';
import Industrie from './Sectoren/industrie';
import Horeca from './Sectoren/horeca';
import Residentieel from './Sectoren/residentieel';
import Landbouw from './Sectoren/landbouw';
import './sector.css';

const sectorComponents = {
    industrie: Industrie,
    horeca: Horeca,
    residentieel: Residentieel,
    landbouw: Landbouw
};

const Sector = ({ sector }) => {
    const SelectedSector = sectorComponents[sector] || (() => <div>Sector niet gevonden</div>);
    return <SelectedSector />;
}

export default Sector;

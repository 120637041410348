import React from 'react';
import finis from '../../../../Images/Controller.png';
import batterydisplay from '../../../../Images/batteryStack.png';
import screenDisplay from '../../../../Images/Schermafbeelding 2023-08-16 164524slanted.png';

const Residentieel = () => {
    return (
        <div className="sectoren__body">
            <h1 className='sectoren__header'>Residentieel</h1>
            <section className="sectoren__section">
                <img src={finis} alt="Finis" className="sectoren__image" />
                <div className="sectoren__text">
                    <p>
                        Hebt u verwarming via warmtepomp, een Elektrische wagen, een hoger verbruik dan gemiddeld door zwembad of jacuzzi? Toch nog hoog verbruik in de winter indien zonnepanelen weinig produceren? Te kleine thuisbatterij van 10kWh om uw wagen van 50kWh op te laden?
                    </p>
                    <h2>FIRN energy aanbod</h2>
                    <ul>
                        <li>FIRN Energie Management</li>
                    </ul>
                    <p>
                        Werkt op de DAY AHEAD energie prijs per uur.
                        <br />
                        Automatische aankoop in de winter op de lage uurprijzen als er niet voldoende productie is van de zonnepanelen.
                        <br />
                        Bij overschot in de zomer energie verkopen aan dure uurprijzen i.p.v. bijna gratis weg te geven.
                    </p>
                    <p>
                        Grotere verbruikers sturen in functie van energieprijzen en eigen productie of energieopslag.
                    </p>
                    <p>
                        Verbruik en opslag sturen via AI voorspelde eigen verbruik/productie/uurprijzen.
                    </p>
                </div>
            </section>

            <section className="sectoren__section">
                <div className="sectoren__text sectoren__text--brown">
                    <h2>FIRN Energie Storage</h2>
                    <p>
                        De energie opslag is het buffervat om op de variabele energieprijzen in te spelen en het eigen verbruik te optimaliseren.
                    </p>
                    <p>
                        De grotere opslagcapaciteit dan standaard thuisbatterijen zorgt ervoor dat u altijd optimaal goedkoop kunt aankopen of duur verkopen. Voldoende capaciteit voor EV’s en andere grotere verbruikers, maar aan een veel lagere kost dan een standaard thuisbatterij.
                    </p>
                    <h2>FIRN omvormers</h2>
                    <p>
                        Retrofit of hybride omvormers. Indien u al zonnepanelen hebt, kunnen we naadloos alles integreren in uw bestaande installatie via een retrofit omvormer. Met een hybride omvormer kan alles in één systeem.
                    </p>
                </div>
                <div className="sectoren__image">
                    <img src={batterydisplay} alt="Battery Display" />
                </div>
            </section>

            <section className="sectoren__section">
                <img src={screenDisplay} alt="Screen Display" className="sectoren__image" />
                <div className="sectoren__text">
                    <h2>FIRN laadpalen</h2>
                    <p>
                        De elektrische wagens worden in sneltempo de grootste energieverbruikers in de woning. De TellToCharge laadpalen integreren in het totaal systeem is een evidentie.
                    </p>
                    <h2>FIRN energiemetingen</h2>
                    <p>
                        Energieproductie en -verbruik meten en visualiseren blijft altijd een basis van een energie management systeem.
                    </p>
                    <h2>FIRN visualisatie</h2>
                    <p>
                        Energieproductie en -verbruik meten en visualiseren, besparingen in kaart brengen zijn mogelijk via een app en website.
                    </p>
                </div>
            </section>
        </div>
    );
}

export default Residentieel;

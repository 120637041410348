import React from 'react';
import './homePageSectoren.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import foto from '../../../Images/shutterstock_2323423867.png'

const homePageSectoren = () => {
    return (
        <div>
            <div className="homePage__sectoren__wrapper">
                <div className="homePage__sectoren__container">
                    <div>
                        <i className="homePage__sectoren__icon fas fa-industry"></i>
                        <h3>Industrie</h3>
                        <p>Maximaliseer de energie van je bedrijf door je energiestroom te regelen.</p>
                        <a href="/Industrie" className="homePage__sectoren__discover">Lees verder<i className='fas fa-arrow-right' /></a>
                    </div>
                    <div>
                        <i className="homePage__sectoren__icon fas fa-house"></i>
                        <h3>Residentieel</h3>
                        <p>Optimaliseer je batterijopstelling door op het juiste moment te importeren en exporteren.</p>
                        <a href="/Residentieel" className="homePage__sectoren__discover">Lees verder<i className='fas fa-arrow-right' /></a>
                    </div>
                    <div>
                        <i className="homePage__sectoren__icon fas fa-tractor"></i>
                        <h3>Landbouw</h3>
                        <p>Vermijd aanzienlijke energiekosten dankzij VLIF en vergroot uw energieopslag.</p>
                        <a href="/Landbouw" className="homePage__sectoren__discover">Lees verder<i className='fas fa-arrow-right' /></a>
                    </div>
                    <div>
                        <i className="homePage__sectoren__icon fas fa-building"></i>
                        <h3>Horeca</h3>
                        <p>Werk met behulp van dagprijzen om je horecazaak efficiënt te runnen.</p>
                        <a href="/Horeca" className="homePage__sectoren__discover">Lees verder<i className='fas fa-arrow-right' /></a>
                    </div>
                </div>
            </div>
            <img src={foto} alt='foto' className='homePage__foto' />
        </div>
    );
}

export default homePageSectoren;

export const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
};

export const newsItems = [
    {
        date: new Date('2024-09-12'),
        text: 'Firn Energy staat 2 & 3 oktober op de Solar Solutions Kortrijk bij stand D2.1',
        hashtags: '#firnenergy #SolarSolutions #Kortrijk',
        imgSrc: "https://www.kortrijkxpo.com/db_files/beurzen/beurzen_foto1_783.png"
    },
    {
        date: new Date('2024-07-26'),
        text: 'Ook in de zomer gaan we ervoor, we presenteren onze nieuwe powerblock aan het landbouw publiek in Libramont. Kom ons bezoeken op stand 58.4',
        hashtags: '#LibramontFoire #2024 #ladbouw',
        imgSrc: "https://clusters.wallonie.be/equisfair/sites/equisfair/files/2024-02/23Banner_foire_1280x480px_P2_fr.jpg"
    },
    {
        date: new Date('2024-06-19'),
        text: 'We zijn enthousiast om onze nieuwste batterijcontaineroplossingen aan te kondigen! Deze op maat gemaakte containers bieden een indrukwekkende capaciteit en veelzijdigheid voor al je energiebehoeften.',
        hashtags: '#firnenergy #partnerships #growth',
        imgSrc: require('../../../Images/container.png')
    },
    {
        date: new Date('2024-06-12'),
        text: 'Een volledige container met onze hoogwaardige batterijen is momenteel onderweg naar ons distributiecentrum.',
        hashtags: '#firn #batterijen #container',
        imgSrc: "https://buffer-media-uploads.s3.amazonaws.com/6665350475a69d6d5203ee6d/0ef48bfd192a3f8d5abaa22ac1e7a08422852d15_3550fd995add65ef812f35f72a86d683813e1d87_facebook"
    },
    {
        date: new Date('2024-06-09'),
        text: 'Wij zijn verheugd om aan te kondigen dat FIRN Energy zal deelnemen aan de prestigieuze Greentech Amsterdam beurs!',
        hashtags: '#Amsterdam #greentech #beurs',
        imgSrc: "https://buffer-media-uploads.s3.amazonaws.com/66680a4bfcaf0d06580cb4e2/4059352cae3e078b69de2ae366602d4b0d87a266_f6d5a55f0e32fa6c54c0ec42f9e5ce1f71c06d0c_facebook"
    },
    {
        date: new Date('2024-09-03'),
        text: 'Infoavond over energietransitie en netwerkevent bij Firn Energy op 19 september in Lokeren. Schrijf je in en ontdek onze nieuwste producten en diensten.',
        hashtags: '#energietransitie #Lokeren #2024',
        imgSrc: require('../../../Images/firn-hor-text.png')
    },
    // Meer nieuwsitems kunnen hier toegevoegd worden
];
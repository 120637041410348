import React from 'react';
import finis from '../../../../Images/Controller.png';
import batterydisplay from '../../../../Images/batteryStack.png';
import screenDisplay from '../../../../Images/Schermafbeelding 2023-08-16 164524slanted.png';

const Landbouw = () => {
    return (
        <div className="sectoren__body">
            <h1 className='sectoren__header'>Landbouw</h1>
            <section className="sectoren__section">
                <img src={finis} alt="Finis" className="sectoren__image" />
                <div className="sectoren__text">
                    <p>
                        De energiekost is ook een zware dobber voor de LANDBOUWSECTOR. Gelukkig is er wel de VLIF steun mogelijk voor de energie opslag: 40% (50% voor jonge landbouwers).
                    </p>
                    <h2>FIRN energy aanbod</h2>
                    <ul>
                        <li>FIRN Energie Management Systeem</li>
                    </ul>
                    <p>
                        Grotere verbruikers sturen in functie van energieprijzen en eigen productie of energieopslag.
                        Verbruik en opslag sturen via AI voorspelde eigen verbruik/productie/uurprijzen.
                        Energie Trading (goedkoop aankopen en duur verkopen met de energieopslag) wordt niet toegelaten voor de VLIF steun, maar met de FIRN controller kunnen we een SPECIFIEKE GEBRUIKSMODE voor de LANDBOUW invoeren.
                    </p>
                </div>
            </section>

            <section className="sectoren__section">
                <div className="sectoren__text sectoren__text--brown">
                    <h2>FIRN Energie Storage</h2>
                    <p>
                        De energie opslag is het buffervat om uw eigen verbruik te optimaliseren en optimaal van uw eigen energie gebruik te kunnen maken.
                        <br />
                        De veel grotere opslagcapaciteit dan standaard thuisbatterijen zorgt dat u een modulair en uitbreidbaar opslagsysteem hebt aangepast aan de typisch veel grotere verbruikers op een landbouwbedrijf.
                    </p>
                    <h2>FIRN omvormers</h2>
                    <p>
                        Retrofit of hybride omvormers. Indien u al zonnepanelen hebt, kunnen we naadloos alles integreren in uw bestaande installatie via een retrofit omvormer.
                        Met een hybride omvormer kan alles in één systeem.
                    </p>
                </div>
                <div className="sectoren__image">
                    <img src={batterydisplay} alt="Battery Display" />
                </div>
            </section>

            <section className="sectoren__section">
                <img src={screenDisplay} alt="Screen Display" className="sectoren__image" />
                <div className="sectoren__text">
                    <h2>FIRN laadpalen</h2>
                    <p>
                        De elektrische wagens nemen ook hun intrede in de landbouwsector. Misschien ook nog in de toekomst voor de zwaardere machines. Met de TellToCharge laadpalen kunnen we alles ook hiervoor in het totaalsysteem integreren.
                    </p>
                    <h2>FIRN energiemetingen</h2>
                    <p>
                        Energieproductie en -verbruik meten en visualiseren blijft altijd een basis van een energie management systeem.
                    </p>
                    <h2>FIRN visualisatie</h2>
                    <p>
                        Energieproductie en -verbruik meten en visualiseren, besparingen in kaart brengen zijn mogelijk via een app en website.
                    </p>
                </div>
            </section>
        </div>
    );
}

export default Landbouw;

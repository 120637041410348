import React, { useState } from 'react'
import Klant from './components/Klant/klant'
import OfferteMetBijlagen from './components/OfferteMetBijlagen/offerteMetBijlagen'
import Berekening from './components/Berekening/berekening'
import { initialKlantData, useRequiredFieldsCheck, initialOrder } from './utils/formHelper'
import data from './data/data.json'
import './assets/styles/components.css'
import './assets/styles/layout.css'
import './assets/styles/media-queries.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
const pjson = require('./../../../../package.json')

const OfferteController = () => {
    const [klantData, setKlantData] = useState(initialKlantData)
    const [tab, setTab] = useState('klant')
    const requiredFields = ['email', 'injectie', 'productie', 'verbruik', 'aankoop', 'verkoop', 'aankoopExcl', 'aansluitvermogen']
    const [isPeriodConfirmed, setIsPeriodConfirmed] = useState(false);
    const [order, setOrder] = useState(initialOrder)
    const [payOptions] = useState(data.betalingsvoorwaarden)
    const [berekeningsData, setBerekeningsData] = useState({
        voorspellingEV: 0,
        onbalansPerkWh: 0,
        onbalans: 0
    })
    const [averagePrice, setAveragePrice] = useState(0);
    const [averageLowPrice, setAverageLowPrice] = useState(0);
    const [averageHighPrice, setAverageHighPrice] = useState(0);
    const [lageAankoopDA, setLageAankoopDA] = useState(0);
    const [hogeVerkoopDA, setHogeVerkoopDA] = useState(0);
    const isTabDisabled = useRequiredFieldsCheck(requiredFields, klantData, isPeriodConfirmed) || !isPeriodConfirmed;

    return (<>
        <header className="offerte__app__header">
            <h1 className="offerte__title">FIRN Energy - Offerte</h1>
        </header>
        <main className="offerte__app__container">
            <div className="offerte__content">
                <div className="offerte__tabs">
                    <button
                        className={`offerte__tab-button ${tab === 'klant' ? 'offerte__tab-button--active' : ''}`}
                        onClick={() => setTab('klant')}
                    >
                        <i className="offerte__icon fas fa-user"></i>
                        Gegevens klant
                    </button>
                    <button
                        className={`offerte__tab-button ${isTabDisabled ? 'offerte__tab-button--disabled' : ''} ${tab === 'offerte' ? 'offerte__tab-button--active' : ''}`}
                        onClick={() => setTab('offerte')}
                        disabled={isTabDisabled}
                    >
                        <i className="offerte__icon fas fa-euro-sign"></i>
                        Offerte
                    </button>
                    <button
                        className={`offerte__tab-button ${isTabDisabled ? 'offerte__tab-button--disabled' : ''} ${tab === 'berekening' ? 'offerte__tab-button--active' : ''}`}
                        onClick={() => setTab('berekening')}
                        disabled={isTabDisabled}
                    >
                        <i className="offerte__icon fas fa-calculator"></i>
                        Gegevens berekening
                    </button>
                </div>
                {tab === 'klant' && (
                    <Klant
                        onEdit={setKlantData}
                        klant={klantData}
                        premies={data.premies}
                        reps={data.contactpersonenFirn}
                        pay={data.betalingsvoorwaarden}
                        setAveragePrice={setAveragePrice}
                        setAverageLowPrice={setAverageLowPrice}
                        setAverageHighPrice={setAverageHighPrice}
                        isPeriodConfirmed={isPeriodConfirmed}
                        setIsPeriodConfirmed={setIsPeriodConfirmed}
                    />
                )}
                {tab === 'offerte' && (
                    <OfferteMetBijlagen
                        klant={klantData}
                        reps={data.contactpersonenFirn}
                        producten={data.producten}
                        btws={data.btw}
                        paymentOptions={payOptions}
                        thisOrder={order}
                        premies={data.premies}
                        install={data.firn}
                        onChangeOfferte={setOrder}
                        updateBerekeningsData={setBerekeningsData}
                        averagePrice={averagePrice}
                        averageLowPrice={averageLowPrice}
                        averageHighPrice={averageHighPrice}
                        setLageAankoopDA={setLageAankoopDA}
                        setHogeVerkoopDA={setHogeVerkoopDA}
                    />
                )}
                {tab === 'berekening' && (
                    <Berekening
                        berekeningsData={berekeningsData}
                        klant={klantData}
                        lageAankoopDA={lageAankoopDA}
                        hogeVerkoopDA={hogeVerkoopDA}
                    />
                )}
            </div>
        </main>
        <footer className="offerte__footer">
            FIRN Energy Offerte - Software version {pjson.version}
        </footer>
    </>)
}

export default OfferteController;
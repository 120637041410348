import React from 'react';
import './oplossingenPage.css';
import { oplossingenItems } from './OplossingsItem';

const OplossingenPage = () => {
    return (
        <div className="oplossingenPage__body">
            <div className="oplossingenPage__grid">
                {oplossingenItems.map(item => (
                    <a href={`${item.link}`} key={item.id} className="oplossingenPage__link">
                        <div className="oplossingenPage__item">
                            <img src={item.foto} alt={`Solution Image ${item.id}`} />
                            <div className="oplossingenPage__details">
                                <p className="oplossingenPage__title">{item.naam}</p>
                                <p className="oplossingenPage__text">{item.korteBeschrijving}</p>
                                <div className="oplossingenPage__advantages">
                                    {item.voordelen.map((voordeel, index) => (
                                        <div key={index}>
                                            <span>+</span> {voordeel}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </a>
                ))}
            </div>
        </div>
    );
}

export default OplossingenPage;

import React from 'react'
import { formatCurrency, formatNumber } from '../../utils/helpers'

const Berekening = ({ berekeningsData, klant, lageAankoopDA, hogeVerkoopDA }) => {
    return (
        <div className="offerte__container">
            <h2 className="offerte__header">Calculatie Gegevens</h2>
            <table className="offerte__table offerte__table--celled">
                <thead className="offerte__table__thead">
                    <tr className="offerte__table__row">
                        <th className="offerte__table__header" colSpan={2}>Verbruik</th>
                        <th className="offerte__table__header" colSpan={2}>Prijs</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="offerte__table__row">
                        <td className="offerte__table__cell offerte__table__cell--four-wide">Jaarverbruik</td>
                        <td className="offerte__table__cell offerte__table__cell--four-wide">{formatNumber(klant.verbruik)} kWh</td>
                        <td className="offerte__table__cell offerte__table__cell--four-wide">Aankoop totaalkost</td>
                        <td className="offerte__table__cell offerte__table__cell--four-wide">{formatCurrency(klant.aankoop)}/kWh</td>
                    </tr>
                    <tr className="offerte__table__row">
                        <td className="offerte__table__cell">Productie</td>
                        <td className="offerte__table__cell">{formatNumber(klant.productie)} kWh</td>
                        <td className="offerte__table__cell">Verkoop Injectie PV</td>
                        <td className="offerte__table__cell">{formatCurrency(klant.verkoop)}/kWh</td>
                    </tr>
                    <tr className="offerte__table__row">
                        <td className="offerte__table__cell">Injectie</td>
                        <td className="offerte__table__cell">{formatNumber(klant.injectie)} kWh</td>
                        <td className="offerte__table__cell">Aankoop Elektriciteit (excl. transport)</td>
                        <td className="offerte__table__cell">{formatCurrency(klant.aankoopExcl)}/kWh</td>
                    </tr>
                </tbody>
            </table>

            <h2 className="offerte__header">Van prijs bedrijf / Raming Piek</h2>
            <table className="offerte__table offerte__table--celled">
                <tbody>
                    <tr className="offerte__table__row">
                        <td className="offerte__table__cell offerte__table__cell--four-wide">Totaalprijs</td>
                        <td className="offerte__table__cell offerte__table__cell--four-wide">{formatCurrency(klant.aankoop)}</td>
                        <td className="offerte__table__cell offerte__table__cell--four-wide">€/kWh</td>
                        <td className="offerte__table__cell offerte__table__cell--four-wide">per Maand</td>
                    </tr>
                </tbody>
            </table>

            <h2 className="offerte__header">PV</h2>
            <table className="offerte__table offerte__table--definition offerte__table--celled">
                <tbody>
                    <tr className="offerte__table__row">
                        <td className="offerte__table__cell offerte__table__cell--four-wide">Installatie</td>
                        <td className="offerte__table__cell offerte__table__cell--three-wide">{formatNumber(klant.productie / 1000)}</td>
                        <td className="offerte__table__cell offerte__table__cell--three-wide">kWp</td>
                        <td className="offerte__table__cell offerte__table__cell--three-wide"></td>
                        <td className="offerte__table__cell offerte__table__cell--three-wide"></td>
                    </tr>
                    <tr className="offerte__table__row">
                        <td className="offerte__table__cell">Productie</td>
                        <td className="offerte__table__cell">{formatNumber(klant.productie)}</td>
                        <td className="offerte__table__cell">kWh</td>
                        <td className="offerte__table__cell">{klant.productie > 0 ? (klant.productie / (klant.productie / 1000)).toFixed(2) : 0}</td>
                        <td className="offerte__table__cell">kWh/kWp</td>
                    </tr>
                    <tr className="offerte__table__row">
                        <td className="offerte__table__cell">Injectie</td>
                        <td className="offerte__table__cell">{formatNumber(klant.injectie)}</td>
                        <td className="offerte__table__cell">kWh</td>
                        <td className="offerte__table__cell">{klant.injectie > 0 ? (klant.injectie / klant.productie * 100).toFixed(0) : 0}%</td>
                        <td className="offerte__table__cell">{formatCurrency(klant.verkoop * klant.injectie)}</td>
                    </tr>
                    <tr className="offerte__table__row">
                        <td className="offerte__table__cell">Eigen Verbruik</td>
                        <td className="offerte__table__cell">{formatNumber(klant.productie - klant.injectie)}</td>
                        <td className="offerte__table__cell">kWh</td>
                        <td className="offerte__table__cell"></td>
                        <td className="offerte__table__cell"></td>
                    </tr>
                    <tr className="offerte__table__row">
                        <td className="offerte__table__cell">Gemiddelde Inkomsten per kWh</td>
                        <td className="offerte__table__cell">{formatNumber(klant.verkoop)}</td>
                        <td className="offerte__table__cell">€/kWh</td>
                        <td className="offerte__table__cell"></td>
                        <td className="offerte__table__cell"></td>
                    </tr>
                </tbody>
            </table>

            <table className="offerte__table offerte__table--definition offerte__table--celled">
                <tbody>
                    <tr className="offerte__table__row">
                        <td className="offerte__table__cell offerte__table__cell--four-wide">RAMING Eigen verbruik</td>
                        <td className="offerte__table__cell offerte__table__cell--three-wide">{formatNumber(klant.productie - klant.injectie)}</td>
                        <td className="offerte__table__cell offerte__table__cell--three-wide">kWh</td>
                        <td className="offerte__table__cell" colSpan={2}>{klant.productie - klant.injectie > 0 ? ((klant.productie - klant.injectie) / klant.productie * 100).toFixed(0) : 0}%</td>
                    </tr>
                </tbody>
            </table>

            {berekeningsData && (
                <>
                    <h2 className="offerte__header">Diversen</h2>
                    <table className="offerte__table offerte__table--definition offerte__table--celled">
                        <tbody>
                            <tr className="offerte__table__row">
                                <td className="offerte__table__cell offerte__table__cell--eight-wide">Voorspelling eigen verbruik</td>
                                <td className="offerte__table__cell" colSpan={2}>{(berekeningsData.voorspellingEV).toFixed(2)}%</td>
                            </tr>
                            <tr className="offerte__table__row">
                                <td className="offerte__table__cell offerte__table__cell--eight-wide">Aankoop op lage energieprijzen Day Ahead</td>
                                <td className="offerte__table__cell">{formatCurrency(lageAankoopDA, 3)}</td>
                                <td className="offerte__table__cell">per kWh</td>
                            </tr>
                            <tr className="offerte__table__row">
                                <td className="offerte__table__cell offerte__table__cell--eight-wide">Verkoop op hoge energieprijzen Day Ahead</td>
                                <td className="offerte__table__cell">{formatCurrency(hogeVerkoopDA, 3)}</td>
                                <td className="offerte__table__cell">per kWh</td>
                            </tr>
                            <tr className="offerte__table__row">
                                <td className="offerte__table__cell offerte__table__cell--eight-wide">Voordeel lagere aankoopprijs op Day Ahead</td>
                                <td className="offerte__table__cell">{formatCurrency(klant.aankoopExcl - lageAankoopDA, 3)}</td>
                                <td className="offerte__table__cell">per kWh</td>
                            </tr>
                            <tr className="offerte__table__row">
                                <td className="offerte__table__cell offerte__table__cell--eight-wide">Voordeel hogere verkoopprijs op Day Ahead</td>
                                <td className="offerte__table__cell">{formatCurrency(hogeVerkoopDA - klant.verkoop, 3)}</td>
                                <td className="offerte__table__cell">per kWh</td>
                            </tr>
                            <tr className="offerte__table__row">
                                <td className="offerte__table__cell offerte__table__cell--eight-wide">Raming opbrengst op onbalans prijzen, per kWh batterij</td>
                                <td className="offerte__table__cell">{berekeningsData.onbalansPerkWh ? formatCurrency(berekeningsData.onbalansPerkWh * (klant.simOnbalans / 100)) : ''}</td>
                                <td className="offerte__table__cell">{berekeningsData.onbalans ? formatCurrency(berekeningsData.onbalans) : ''}</td>
                            </tr>
                        </tbody>
                    </table>
                </>
            )}
        </div>
    )
}

export default Berekening

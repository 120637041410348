import { useEffect, useState } from 'react';
import './batteryChart.css'
import { ResponsiveContainer, AreaChart, XAxis,YAxis, CartesianGrid, Tooltip, ReferenceLine, Area, CartesianAxis, Legend, ComposedChart, Line} from 'recharts'
import Policy from '../../../../api/policy';
import GraphValues from '../../../../api/graphValues';


import { Spin } from 'antd'
import { format } from 'date-fns';
import User from '../../../../api/user';


const BatteryChart = ({serialNr, TriggerActivity, triggerDataRetrieval}) => {

    const userApi = User()
    const policyApi = Policy();
    const graphValuesApi = GraphValues();
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (serialNr) {
            
            //update every hour
            retrieveData(triggerDataRetrieval)
           
        }
    }, [serialNr, triggerDataRetrieval])


    const retrieveData = async(date) => {
        //SOC

        try {
            setLoading(true)
            const soc = await graphValuesApi.getGraphValue("soc", serialNr, date, "5min");
            
            const dayahead = await userApi.getDayAheadPrice();

            const currentDate = new Date(date);
            const startOfCurrentDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
            const endOfCurrentDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1);

            let startIndex = -1;
            let endIndex = -1;

            const newDayAhead = []
            for (let i = 0; i < dayahead.time.length; i++) {
                const dateObj = new Date(dayahead.time[i]);
                if (dateObj >= startOfCurrentDay && dateObj < endOfCurrentDay) {
                    if (startIndex === -1) {
                        startIndex = i;
                    }
                    endIndex = i;
                    newDayAhead.push({time: dateObj, value: dayahead.values[i]})
                }
            }

            let index = 0;
            let hour = 0;
            let minute = 0;
            let newSOC = [];
            for (let i = 0; i < 288; i++) {
                if (soc[index] && parseInt(soc[index].time.split(':')[0]) == hour && parseInt(soc[index].time.split(':')[1]) == minute) {
                    newSOC.push({time: soc[index].time, value: soc[index].value})
                    index++
                    minute += 5
                } else {
                    newSOC.push({time: `${hour < 10 ? `0${hour}` : hour}:${minute < 10 ? `0${minute}` : minute}:00`, value: null})
                    minute += 5
                }

                if (minute == 60) {
                    minute = 0
                    hour++
                }
            }

            try {
                const policys = await policyApi.getPolicys(serialNr, date);
            
                const datetime = date
                let indexToGet = datetime.getHours() * 12 + Math.ceil(datetime.getMinutes() / 5)
                let activity = "Stand-By"
                if (policys[indexToGet]) {
                    if (policys[indexToGet].powerSetpoint_W > 0) {
                        activity = "Charging"
                    } else if(policys[indexToGet].powerSetpoint_W < 0) {
                        activity = "Discharging"
                    }
                }
                TriggerActivity(activity)
    
                const dataFormat = []
                //reformat soc to 5 min intervals
                const today = date;
                const year = today.getFullYear();
                const month = today.getMonth();
                const day = today.getDate();
    
                for (let i = 0; i < newSOC.length; i++) {
                    const [hours, minutes, seconds] = newSOC[i].time.split(':').map(Number);
                    const timeFormat = new Date(year, month, day, hours, minutes, seconds);
                    const newItem = {time: timeFormat, percentage: newSOC[i].value, policyNeg: policys.powerSetpoint_W[i] < 0 ? 100 : null, policyPos: policys.powerSetpoint_W[i] > 0 ? 100 : null}
                    dataFormat.push(newItem)
                }
    
                
                const newdataformat = dataFormat.map((item, i) => {
                    if (i % 12 == 0) {
                        return {
                            ...item,
                            dayAhead: newDayAhead[i / 12].value * 100
                        }
                    } else {
                        return {
                            ...item,
                            dayAhead: null
                        }
                    }
                })
    
                let formattedData = formatDataForChart(newdataformat)
    
                setData(formattedData)
            } catch (e) {
                console.log(e)

                const dataFormat = []
                //reformat soc to 5 min intervals
                const today = date;
                const year = today.getFullYear();
                const month = today.getMonth();
                const day = today.getDate();
    
                for (let i = 0; i < newSOC.length; i++) {
                    const [hours, minutes, seconds] = newSOC[i].time.split(':').map(Number);
                    const timeFormat = new Date(year, month, day, hours, minutes, seconds);
                    const newItem = {time: timeFormat, percentage: newSOC[i].value}
                    dataFormat.push(newItem)
                }
    
                
                const newdataformat = dataFormat.map((item, i) => {
                    if (i % 12 == 0) {
                        return {
                            ...item,
                            dayAhead: newDayAhead[i / 12].value * 100
                        }
                    } else {
                        return {
                            ...item,
                            dayAhead: null
                        }
                    }
                })
                console.log(newdataformat)
                let formattedData = formatDataForChart(newdataformat)
    
                setData(formattedData)
            }
            
        } catch (e) {
            console.log(e)
            setData(null)
        }
        setLoading(false)
    } 

    const formatDataForChart = (data) => {
        return data.map(item => ({
            ...item,
            timestamp: item.time.getTime() 
        }));
    };

    return (
        <>
            {loading ? 
                
                <div style={{textAlign: 'center', marginTop: 20}}>
                    <Spin size="large" />
                </div>
            :
        
            <ResponsiveContainer style={{fontSize: "1rem"}} width={"100%"} height="100%">
            
                <ComposedChart data={data}
                margin={{ top: 10, left: 10 }}>
                    <defs>
                        <linearGradient id="bateryPercentage" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="30%" stopColor="#55D6C2" stopOpacity={1}/>
                            <stop offset="100%" stopColor="#82ca9d" stopOpacity={0}/>
                        </linearGradient>
                        <linearGradient id="policysNeg" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="30%" stopColor="#BC2924" stopOpacity={0.5}/>
                            <stop offset="100%" stopColor="#D83530" stopOpacity={1}/>
                        </linearGradient>
                        <linearGradient id="policysPos" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="30%" stopColor="#A8E6CF" stopOpacity={0.5}/>
                            <stop offset="100%" stopColor="#4CAF50" stopOpacity={1}/>
                        </linearGradient>
                    </defs>
                    <XAxis 
                        dataKey={'timestamp'} 
                        interval={35} 
                        axisLine={{strokeWidth: 2, color: "white"}} 
                        height={20} 
                        tickFormatter={(tick) => format(new Date(tick), 'HH:mm')}
                    />
                    <YAxis tick={false} width={4} axisLine={{strokeWidth: 2}}/>
                    <YAxis unit={'€'} yAxisId={"dayAheadYAxis"} orientation='right' axisLine={{strokeWidth: 2}}/>
                    <Legend verticalAlign="bottom" iconSize={12} payload={[{value: 'SOC', type: 'circle', color: '#55D6C2'}, { value: 'Charging', type: 'circle', color: '#7DD835' }, { value: 'Discharging', type: 'circle', color: '#D83535'}, {value: 'Day-ahead price', type: 'circle', color: '#D79922'}]}/>
                    <Area type="monotone" dataKey="percentage" stroke="#55D6C2" fill="url(#bateryPercentage)" />
                    <Area type="monotone" dataKey="policyNeg" stroke="" fill="url(#policysNeg)" />
                    <Area type="monotone" dataKey="policyPos" stroke="" fill="url(#policysPos)" />
                    <Line yAxisId={"dayAheadYAxis"} connectNulls={true} dot={false} stroke='#D79922' type="monotone" dataKey="dayAhead" strokeWidth={2}/>
                </ComposedChart>
            </ResponsiveContainer>
            }
        </>
    )

}


export default BatteryChart;
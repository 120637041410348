import React from 'react';
import { newsItems, formatDate } from '../NewsPage/NewsItems';
import './homePageNewsAlert.css';

const HomePageNewsAlert = ({scrollToNews}) => {
    const latestNewsItem = newsItems.reduce((latest, item) => {
        return item.date > latest.date ? item : latest;
    }, newsItems[0]);

    return (
        <div className="homePage__news-alert">
            <div className="homePage__news-alert__hexagon homePage__news-alert__hexagon-link">
                <p onClick={scrollToNews} className='homePage__news-alert__link'>Meer Nieuws</p>
            </div>
            <div className="homePage__news-alert__hexagon homePage__news-alert__hexagon-image">
                <img src={latestNewsItem.imgSrc} alt="Latest News" />
            </div>
            <div className="homePage__news-alert__hexagon homePage__news-alert__hexagon-text">
                <p>{latestNewsItem.text}</p>
            </div>
        </div>
    );
};

export default HomePageNewsAlert;

import React, { useState } from 'react'
import Offerte from './Offerte/offerte'
import Bijlage1 from './Bijlagen/bijlage1'
import Bijlage2 from './Bijlagen/bijlage2'

const OfferteMetBijlagen = ({
    install,
    klant,
    reps,
    paymentOptions,
    producten,
    btws,
    thisOrder,
    premies,
    updateBerekeningsData,
    averagePrice,
    averageLowPrice,
    averageHighPrice,
    setLageAankoopDA,
    setHogeVerkoopDA
}) => {
    const [totalPrice] = useState(0)
    const [totaleOmvormcapaciteit, setTotaleOmvormcapaciteit] = useState(0);
    const [totaleBatterijcapaciteit, setTotaleBatterijcapaciteit] = useState(0);
    const [systemsVerhoudingen, setSystemsVerhoudingen] = useState([0, 0, 0]);
    const [prices, setPrices] = useState({
        controllerPrijs: 0,
        totaalControllerPrijs: 0,
        energiemeterPrijs: 0,
        totaalEnergiemeterPrijs: 0,
        abonnementPrijs: 0,
        totaalAbonnementPrijs: 0,
        korting: 0,
        bebatPrijs: 0,
        btwPrijs: 0,
        totaalExclBtw: 0,
        totaalInclBtw: 0,
        totaalPrijsPremies: 0
    });
    const [tabelData, setTabelData] = useState({
        huidigeKost: 0,
        voordeelEV: 0,
        voordeelDA: 0,
        voordeelOnbalans: 0,
        voordeelTotaal: 0,
        vlif: 0,
        invest: 0,
        eco: 0,
        premie: 0
    })
    console.log(totaleOmvormcapaciteit + '/' + totaleBatterijcapaciteit)

    return (
        <div className="offerte__container offerte__fluid-container">
            <div className="offerte__wrapper">
                <Offerte
                    install={install}
                    klant={klant}
                    reps={reps}
                    paymentOptions={paymentOptions}
                    totalPrice={totalPrice}
                    producten={producten}
                    btws={btws}
                    thisOrder={thisOrder}
                    totaleOmvormcapaciteit={totaleOmvormcapaciteit}
                    setTotaleOmvormcapaciteit={setTotaleOmvormcapaciteit}
                    totaleBatterijcapaciteit={totaleBatterijcapaciteit}
                    setTotaleBatterijcapaciteit={setTotaleBatterijcapaciteit}
                    prices={prices}
                    setPrices={setPrices}
                    setSystemsVerhoudingen={setSystemsVerhoudingen}
                />
                <Bijlage1
                    klant={klant}
                    batterij={totaleBatterijcapaciteit}
                    producten={producten}
                    updateTabelData={setTabelData}
                    prices={prices}
                    premies={premies}
                    systemsVerhoudingen={systemsVerhoudingen}
                    updateBerekeningsData={updateBerekeningsData}
                    averagePrice={averagePrice}
                    averageLowPrice={averageLowPrice}
                    averageHighPrice={averageHighPrice}
                    totaleOmvormcapaciteit={totaleOmvormcapaciteit}
                    setLageAankoopDA={setLageAankoopDA}
                    setHogeVerkoopDA={setHogeVerkoopDA}
                />
                <Bijlage2
                    klant={klant}
                    premies={premies}
                    tabelData={tabelData}
                    prices={prices}
                />
            </div>
        </div>
    )
}

export default OfferteMetBijlagen

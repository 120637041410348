import { useContext, useEffect, useState } from 'react'
import './dashboardCalendar.css'
import Calendar from '../../../api/calendar';
import { DarkModeContext } from '../../../context/DarkModeContext'


const DashboardCalendar = ({ToggleLoading, accountType, setChangesMade, changesMade, setChangesMadewithoutSaving, serialNr}) => {

    const {darkMode} = useContext(DarkModeContext);
    const calendarApi = Calendar();

    const [refDate, setRefDate] = useState(new Date());
    const [calendarDatesData, setCalendarDatesData] = useState([]);
    const [dates, setDates] = useState([
        {day: 'Mon', calendarDates: [0, 5, 12, 19, 26]},
        {day: 'Tue', calendarDates: [0, 6, 13, 20, 27]},
        {day: 'Wed', calendarDates: [0, 7, 14, 21, 28]},
        {day: 'Thu', calendarDates: [1, 8, 15, 22, 29]},
        {day: 'Fri', calendarDates: [2, 9, 16, 23, 30]},
        {day: 'Sat', calendarDates: [3, 10, 17, 24, 0]},
        {day: 'Sun', calendarDates: [4, 11, 18, 25, 0]}
    ]);
    
    useEffect(() => {
        ToggleLoading(true)

        //get data from erik
        getData()

        setDates(getDatesOfMonth(refDate.getMonth(), refDate.getFullYear()))

    }, [refDate])

    const getData = async() => {
        try {

            const data = await calendarApi.getCalendarData(refDate.getMonth() + 1, refDate.getFullYear(), serialNr);
            
            if (data.calendar_dates.length < 1) {
                throw new Error("empty calendardatesList")
            }

            const daysInMonth = new Date(refDate.getFullYear(), refDate.getMonth() + 1, 0).getDate();

            const newdatalist = []

            for (let i = 0; i < data.calendar_dates.length; i++) {
                
                try {
                    const element = data.calendar_dates[i];

                    const date = element.date
                    const index = parseInt(date.split("-")[2])
    
                    newdatalist[index-1] = element
                } catch(e) {
                    console.log(e)
                }   
            }

            setCalendarDatesData(newdatalist)


        } catch (e) {

            setCalendarDatesData(null)

        }
        ToggleLoading(false)
    }


    function getDatesOfMonth(month, year) {
        const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
        const firstDayOfMonth = new Date(year, month, 1).getDay() - 1;
        const daysInMonth = new Date(year, month + 1, 0).getDate();
        const dates = [];
        
        for (let i = 0; i < daysOfWeek.length; i++) {
            
            
            let j = i - firstDayOfMonth + 1
            const startNumber = j

            const calendarDates = []

            while(j <= daysInMonth) {
                if (j > 0) {
                    calendarDates.push(j)
                } else {
                    calendarDates.push(0)
                }
                j += 7;
            }

            if (calendarDates.length < 5) {
                calendarDates.push(0)
            }

            dates.push(
                {day: daysOfWeek[i], calendarDates: calendarDates}
            )
        }
        
        return dates;
    }

    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];

    const handleDateChange = (before) => {
        //check if there are any changes
        if (changesMade) {
            setChangesMadewithoutSaving(true)
        } else {
            ToggleLoading(true)
            setRefDate(prevDate => {
                const newDate = new Date(prevDate); // Create a new Date object to avoid mutating state directly
                if (before) {
                    // Go to the previous month
                    newDate.setMonth(newDate.getMonth() - 1);
                } else {
                    // Go to the next month
                    newDate.setMonth(newDate.getMonth() + 1);
                }
    
                return newDate; // Return the updated date
            });
            ToggleLoading(false)
        }
    }

    function CalendarBox({date, AM, PM}) {

        const [voorMiddag, setVoorMiddag] = useState(AM)
        const [naMiddag, setNaMiddag] = useState(PM)

        const handleVoormiddagChange = () => {
            setChangesMade(true)

            setVoorMiddag(!voorMiddag)
            const newData = calendarDatesData;

            newData[date - 1]['am'] = !voorMiddag ? 1 : 0;
            setCalendarDatesData(newData)
            
        }

        const handleNamiddagChange = () => {
            setChangesMade(true)

            setNaMiddag(!naMiddag)
            const newData = calendarDatesData;
            newData[date - 1]['pm'] = !naMiddag ? 1 : 0;
            setCalendarDatesData(newData)
            
        }

        if (date == 0) {
            return (
                <div className='Dashboard-Calendar-DayView-Box-Container inActive' style={darkMode ? {border: 'solid #636161 2px'} : {border: 'solid #2C2F33 2px'}}>
                
                </div>
            )
        } else {
            return (
                <div className='Dashboard-Calendar-DayView-Box-Container' style={darkMode ? {border: 'solid #555555 2px'} : {border: 'solid #2C2F33 2px'}}>
                    <div className='Dashboard-Calendar-DayView-Box-Date-Container' style={darkMode ? {borderBottom: 'solid #555555 2px'} : {borderBottom: 'solid #2C2F33 2px'}}>{date < 10 ? `0${date}` : `${date}`} {months[refDate.getMonth()]}</div>
                    {calendarDatesData && calendarDatesData[date-1] &&
                        <div className='Dashboard-Calendar-DayView-Box-Input-Container'>
                            <div className='Dashboard-Calendar-DayView-Box-Input-Divider' style={darkMode ? {backgroundColor: '#555555'} : {backgroundColor: '#2C2F33'}}></div>
                            <div className='Dashboard-Calendar-DayView-Box-Input-Box-Container'>
                                <div className='Dashboard-Calendar-DayView-Box-Input-Box-Slider-Container first' style={darkMode ? {borderBottom: 'solid #555555 2px'} : {borderBottom: 'solid #2C2F33 2px'}}>
                                    <div className='Dashboard-Calendar-DayView-Box-Input-Box-Slider Percent'>{voorMiddag ? "100%" : "0%"}</div>
                                    <div className='Dashboard-Calendar-DayView-Box-Input-Box-Slider Button'>
                                        <div onClick={() => handleVoormiddagChange()} className={`Dashboard-Calendar-DayView-Box-Input-Box-Slider-Rect ${voorMiddag ? 'active' : 'inactive'}`}>
                                            <div className={`Dashboard-Calendar-DayView-Box-Input-Box-Slider-Circle ${voorMiddag ? 'moved' : 'inactive'}`}></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='Dashboard-Calendar-DayView-Box-Input-Box-Slider-Container'>
                                    <div className='Dashboard-Calendar-DayView-Box-Input-Box-Slider Percent'>{naMiddag ? "100%" : "0%"}</div>
                                    <div className='Dashboard-Calendar-DayView-Box-Input-Box-Slider Button'>
                                        <div onClick={() => handleNamiddagChange()} className={`Dashboard-Calendar-DayView-Box-Input-Box-Slider-Rect ${naMiddag ? 'active' : 'inactive'}`}>
                                            <div className={`Dashboard-Calendar-DayView-Box-Input-Box-Slider-Circle ${naMiddag ? 'moved' : 'inactive'}`}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            )
        }
    }

    const handleApply = async() => {
        
        ToggleLoading(true)
        const response = await calendarApi.updateCalendarData(calendarDatesData, serialNr)
        setChangesMade(false)
        ToggleLoading(false)
    }


    return (
        <div style={{width: '100%', display: 'flex', justifyContent:'center'}}>
        <div className='Dashboard-Calendar-Container' style={darkMode ? {backgroundColor: '#232323', color: 'white', border: '#2E2E2E solid 2px'} : {backgroundColor: 'rgb(217, 217, 217)', color: 'black', border: '#2E2E2E solid 2px'}}>
            <div className='Dashboard-Calendar-Date-Container'>
                <div onClick={() => handleDateChange(true)} className='Dashboard-Calendar-Date-Before'>
                    <div className='Dashboard-Calendar-Date-Arrow Up' style={darkMode ? {backgroundColor: '#D9D9D9'} : {backgroundColor: '#2C2F33'}}></div>
                    <div className='Dashboard-Calendar-Date-Arrow Down' style={darkMode ? {backgroundColor: '#D9D9D9'} : {backgroundColor: '#2C2F33'}}></div>
                </div>
                <div className='Dashboard-Calendar-Date-Month'>{months[refDate.getMonth()]}</div>
                <div className='Dashboard-Calendar-Date-Year'>{refDate.getFullYear()}</div>
                <div onClick={() => handleDateChange(false)} className='Dashboard-Calendar-Date-After'>
                    <div className='Dashboard-Calendar-Date-Arrow Up' style={darkMode ? {backgroundColor: '#D9D9D9'} : {backgroundColor: '#2C2F33'}}></div>
                    <div className='Dashboard-Calendar-Date-Arrow Down' style={darkMode ? {backgroundColor: '#D9D9D9'} : {backgroundColor: '#2C2F33'}}></div>
                </div>
                <div className='Dashboard-Calendar-Apply-Button'>
                    <button 
                        style={{
                            backgroundColor: '#4CAF50', /* Green */
                            border: 'none',
                            color: 'white',
                            padding: '10px 15px',
                            textAlign: 'center',
                            textDecoration: 'none',
                            display: 'inline-block',
                            fontSize: '16px',
                            borderRadius: '12px',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                            cursor: 'pointer',
                            fontWeight: 'bold',
                            transition: 'background-color 0.3s ease',
                        }}
                        onClick={handleApply}>Save changes
                    </button>
                </div>
            </div>
            <div className='Dashboard-Calendar-MonthView-Container'>
                {dates.map((item, index) => (
                    <div className='Dashboard-Calendar-DayView-Container'>
                    <div>{item.day}</div>

                    {item.calendarDates.map((date, index) => {
                        let voormiddag = 0
                        let namiddag = 0
                        if (date > 0 && calendarDatesData && calendarDatesData[date - 1]) {
                            voormiddag = calendarDatesData[date - 1]['am'] == 1
                            namiddag = calendarDatesData[date - 1]['pm'] == 1
                        }
                        return (
                            <CalendarBox date={date} AM={voormiddag} PM={namiddag}/>
                        )
                    })}
                </div>
                ))}
            </div>
        </div>
        </div>
    )

}

export default DashboardCalendar;
import './signUpForm.css'
import User from '../../../api/user'
import { useState } from 'react'

const SignUpPage = () => {

    const userApi = User()

    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        company: '',
        aantalPersonen: 1,
        location: 'Restaurant Wybo, Staatsbaan 29, 9870 Zulte, 12/09/2024',
    })

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };

    const sendData = async (e) => {
        e.preventDefault();

        try {
            await userApi.SignupForm(formData.name, formData.email, formData.phone, formData.aantalPersonen, formData.company, formData.location);
        
            setFormSubmitted(true);
        
            setFormData({
              name: '',
              email: '',
              phone: '',
              company: '',
              aantalPersonen: 1,
              location: '',
            });
        } catch (error) {
            console.error('Error submitting form:', error);
        }
          

    };

    const locationOptions = [
        { value: 'Restaurant Wybo, Staatsbaan 29, 9870 Zulte, 12/09/2024', label: 'Restaurant Wybo, Staatsbaan 29, 9870 Zulte, 12/09/2024' },
        { value: 'Biznis Hotel, Zelebaan 100, 9160 Lokeren, 19/09/2024', label: 'Biznis Hotel, Zelebaan 100, 9160 Lokeren, 19/09/2024' }
    ];

    return (
        <div className="form__container">
            <div className="form__overlay"></div>
                <div className="form__content">
                    <h2 className='form__title'>Inschrijvingsformulier FIRN energy infosessies</h2>
                    {formSubmitted && (
                        <div className="form__success-alert">
                            Bedankt voor uw inschrijving!
                        </div>)
                    }
                    <form onSubmit={sendData}>
                    <div className="form-group">
                        <label className='form__label' for="name">Naam:</label>
                        <input className='form__input' type="text" id="name" name="name" value={formData.name} 
                            onChange={handleInputChange} required />
                    </div>
                    <div className="form-group">
                        <label className='form__label' for="email">Email:</label>
                        <input className='form__input' type="email" id="email" name="email" value={formData.email} 
                            onChange={handleInputChange} required />
                    </div>
                    <div className="form-group">
                        <label className='form__label' for="phone">Telefoon nummer:</label>
                        <input className='form__input' type="tel" id="phone" name="phone" value={formData.phone} 
                            onChange={handleInputChange} required />
                    </div>
                    <div className="form-group">
                        <label className='form__label' for="company">Bedrijfsnaam:</label>
                        <input className='form__input' type="text" id="company" name="company" value={formData.company} 
                            onChange={handleInputChange} required />
                    </div>
                    <div className="form-group">
                        <label className='form__label' for="aantalPersonen">Aantal personen:</label>
                        <input className='form__input' type="number" id="aantalPersonen" name="aantalPersonen" value={formData.aantalPersonen} 
                            onChange={handleInputChange} min={1} required />
                    </div>
                    <div className="form-group">
                        <label className='form__label' htmlFor="location">Locatie:</label>
                        <select className='form__input' id="location" name="location" value={formData.location} onChange={handleInputChange} required>
                            {locationOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                            ))}
                        </select>
                    </div>
                    <button className='form__submit__btn' type="submit">Schrijf u in</button>
                    </form>
                </div>
            </div>
    )
}

export default SignUpPage
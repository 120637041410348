import React, { useState, useEffect, useCallback } from 'react'
import { formatCurrency } from "../../../utils/helpers"

const SysteemRijen = ({ producten, totalOrder, setTotalOrder, handleCounter, handleSelect, updateSystemTotal, updateTotalWeight, updateTotalBatterijCap, updateTotalOmvormCap, updateTotalOmvormers, updateSystemsVerhoudingen }) => {
    const omvormers = producten.omvormers
    const batterijcapaciteiten = producten.batterijen.batterijcapaciteiten
    const [omvormerList, setOmvormerList] = useState([])
    const [batterijCapList, setBatterijCapList] = useState([])
    const [visibleSystems, setVisibleSystems] = useState({ 2: false, 3: false, });

    useEffect(() => {
        if (omvormers && omvormers.options) {
            const omvormerOptions = omvormers.options.map((omvormer) => ({
                value: omvormer.label,
                text: omvormer.label
            }))
            setOmvormerList(omvormerOptions)
        }

        if (batterijcapaciteiten && batterijcapaciteiten.options) {
            const batterijOptions = Object.keys(batterijcapaciteiten.options).map(key => ({
                value: key,
                text: `${batterijcapaciteiten.options[key]} kWh`
            }))
            setBatterijCapList(batterijOptions)
        }
    }, [omvormers, batterijcapaciteiten])

    const resetSystem = (systemNumber) => {
        setTotalOrder(prevOrder => ({
            ...prevOrder,
            [`omvormer${systemNumber}`]: { key: "", num: 0 },
            [`batterijCapaciteit${systemNumber}`]: { key: "" }
        }));
    }

    const toggleVisibility = (systemNumber) => {
        setVisibleSystems(prevState => ({
            ...prevState,
            [systemNumber]: !prevState[systemNumber]
        }));

        if (visibleSystems[systemNumber]) {
            resetSystem(systemNumber);
        }
    };

    const handleOmvormerSelect = (event) => {
        const { name, value } = event.target;
        const systemNumber = name.replace('omvormer', '');

        if (value === 'FIRN High Power block 115/233') {
            setTotalOrder(prevOrder => ({
                ...prevOrder,
                [`batterijCapaciteit${systemNumber}`]: { key: "" }
            }));
        }

        handleSelect(event);
    };

    const calculateCommonValues = useCallback((systemNumber) => {
        const selectedOmvormer = omvormers.options.find(
            omv => omv.label === totalOrder[`omvormer${systemNumber}`]?.key
        );

        const batterij = selectedOmvormer ? producten.batterijen.options.find(
            (bat) => bat.label === selectedOmvormer.batterij
        ) : null;

        const aantalOmvormers = totalOrder[`omvormer${systemNumber}`]?.num || 0;
        const selectedBatterijCap = batterijcapaciteiten.options[totalOrder[`batterijCapaciteit${systemNumber}`]?.key || ''] || 0;

        return { selectedOmvormer, batterij, aantalOmvormers, selectedBatterijCap };
    }, [batterijcapaciteiten.options, omvormers.options, producten.batterijen.options, totalOrder]);

    const calculateBatteries = useCallback((systemNumber) => {
        const { selectedBatterijCap, aantalOmvormers } = calculateCommonValues(systemNumber);
        if (!selectedBatterijCap) return 0;
        return selectedBatterijCap * (aantalOmvormers || 1) / 5;
    }, [calculateCommonValues]);

    const calculateBCUs = useCallback((systemNumber) => {
        const { aantalOmvormers, selectedBatterijCap } = calculateCommonValues(systemNumber);
        return selectedBatterijCap > 40 ? aantalOmvormers : 0;
    }, [calculateCommonValues]);

    const calculatePrices = useCallback((systemNumber) => {
        const { selectedOmvormer, batterij, aantalOmvormers } = calculateCommonValues(systemNumber);

        const bcuPrijs = producten.bcu?.verkoopprijs || 0;
        const omvormerPrijs = selectedOmvormer?.verkoopprijs || 0;
        const batterijPrijs = batterij?.verkoopprijs || 0;

        const eenheidsprijs = aantalOmvormers
            ? omvormerPrijs + (calculateBatteries(systemNumber) * batterijPrijs + calculateBCUs(systemNumber) * bcuPrijs) / aantalOmvormers
            : 0;

        const totaalprijs = eenheidsprijs * aantalOmvormers;

        return { eenheidsprijs, totaalprijs };
    }, [producten, calculateBCUs, calculateBatteries, calculateCommonValues]);

    const calculateSystemMetric = useCallback((systemNumber, metric) => {
        const { selectedOmvormer, aantalOmvormers, selectedBatterijCap } = calculateCommonValues(systemNumber);
        if (!selectedOmvormer || !aantalOmvormers) return 0;

        if (metric === 'weight') {
            if (selectedOmvormer.label === 'FIRN High Power block 115/233') { return 0; }
        
            const batterijGewicht = producten.batterijen.options.find(
                (bat) => bat.label === selectedOmvormer.batterij
            )?.gewicht || 0;
            let aantalBatterijen = calculateBatteries(systemNumber);
            return batterijGewicht * aantalBatterijen;
        }

        if (metric === 'capacity') {
            const batterijCap = producten.batterijen.options.find(
                (bat) => bat.label === selectedOmvormer.batterij
            )?.capaciteit || 0;
            if (selectedOmvormer.label === 'FIRN High Power block 115/233') {
                return 5 * batterijCap * aantalOmvormers;
            }
            return calculateBatteries(systemNumber) * batterijCap;
        }

        if (metric === 'omvormerCap') {
            return selectedOmvormer.omvormcapaciteit * aantalOmvormers;
        }

        const roundRatio = (ratio) => {
            const possibleRatios = [1 / 4, 1 / 3, 1 / 2];
            return possibleRatios.reduce((prev, curr) =>
                Math.abs(curr - ratio) < Math.abs(prev - ratio) ? curr : prev
            );
        };

        if (metric === 'verhouding') {
            if (selectedOmvormer.label === 'FIRN High Power block 115/233') {
                return 1 / 2;
            }
            const ratio = selectedOmvormer.omvormcapaciteit / selectedBatterijCap;
            return roundRatio(ratio);
        }

        return 0;
    }, [calculateCommonValues, calculateBatteries, producten.batterijen.options]);

    const calculateTotalMetrics = useCallback((metric) => {
        const system1Metric = calculateSystemMetric(1, metric);
        const system2Metric = visibleSystems[2] ? calculateSystemMetric(2, metric) : 0;
        const system3Metric = visibleSystems[3] ? calculateSystemMetric(3, metric) : 0;
        return system1Metric + system2Metric + system3Metric;
    }, [calculateSystemMetric, visibleSystems]);

    const calculateTotalOmvormers = useCallback(() => {
        const system1Omvormers = totalOrder[`omvormer1`]?.num || 0;
        const system2Omvormers = visibleSystems[2] ? totalOrder[`omvormer2`]?.num || 0 : 0;
        const system3Omvormers = visibleSystems[3] ? totalOrder[`omvormer3`]?.num || 0 : 0;
        return system1Omvormers + system2Omvormers + system3Omvormers;
    }, [totalOrder, visibleSystems]);

    useEffect(() => {
        const system1Total = calculatePrices(1).totaalprijs;
        const system2Total = visibleSystems[2] ? calculatePrices(2).totaalprijs : 0;
        const system3Total = visibleSystems[3] ? calculatePrices(3).totaalprijs : 0;
        updateSystemTotal(system1Total + system2Total + system3Total);

        const system1Verhouding = calculateSystemMetric(1, 'verhouding');
        const system2Verhouding = visibleSystems[2] ? calculateSystemMetric(2, 'verhouding') : 0;
        const system3Verhouding = visibleSystems[3] ? calculateSystemMetric(3, 'verhouding') : 0;
        updateSystemsVerhoudingen([system1Verhouding, system2Verhouding, system3Verhouding]);

        updateTotalWeight(() => calculateTotalMetrics('weight'));
        updateTotalBatterijCap(() => calculateTotalMetrics('capacity'));
        updateTotalOmvormCap(() => calculateTotalMetrics('omvormerCap'));
        updateTotalOmvormers(calculateTotalOmvormers());
    }, [visibleSystems, calculatePrices, updateSystemTotal, updateTotalWeight, calculateTotalMetrics, updateTotalBatterijCap, updateTotalOmvormCap, calculateSystemMetric, updateTotalOmvormers, calculateTotalOmvormers, updateSystemsVerhoudingen]);

    const SystemRow = ({ systemNumber }) => {
        const { eenheidsprijs, totaalprijs } = calculatePrices(systemNumber);
        const { selectedOmvormer } = calculateCommonValues(systemNumber);
        const isFIRNHighPowerBlock = selectedOmvormer?.label === 'FIRN High Power block 115/233';

        return (<>
            <tr key={`offerte__header-${systemNumber}`} className='offerte__table__row'>
                <td className='offerte__table__cell' colSpan={4}>
                    <h4 className='offerte__header'>Systeem {systemNumber}:</h4>
                </td>
            </tr>
            <tr key={`offerte__details-${systemNumber}`} className='offerte__table__row'>
                <td className='offerte__table__cell'>
                    <label className='offerte__label' htmlFor={`offerte__omvormer${systemNumber}`}>Omvormer:</label>
                    <select
                        id={`offerte__omvormer${systemNumber}`}
                        className='offerte__select'
                        name={`omvormer${systemNumber}`}
                        value={totalOrder[`omvormer${systemNumber}`]?.key || ""}
                        onChange={handleOmvormerSelect}
                    >
                        <option value="" disabled>Selecteer een omvormer</option>
                        {omvormerList.map((omv) => (
                            <option key={omv.value} value={omv.value}>
                                {omv.text}
                            </option>
                        ))}
                    </select>
                </td>
                <td className='offerte__table__cell'>
                    <input
                        type='number'
                        className='offerte__input offerte__input--offset'
                        name={`omvormer${systemNumber}`}
                        onChange={handleCounter}
                        min={0}
                        value={totalOrder[`omvormer${systemNumber}`]?.num || 0}
                    />
                </td>
                <td className='offerte__table__cell'><p className='offerte__input--offset'>{formatCurrency(eenheidsprijs)}</p></td>
                <td className='offerte__table__cell'><p className='offerte__input--offset'>{formatCurrency(totaalprijs)}</p></td>
            </tr>
            {!isFIRNHighPowerBlock && (<>
                <tr key={`offerte__batterij-capaciteit-${systemNumber}`} className='offerte__table__row'>
                    <td className='offerte__table__cell'>
                        <label className='offerte__label' htmlFor={`offerte__batterij-capaciteit${systemNumber}`}>Batterijcapaciteit:</label>
                        <select
                            id={`offerte__batterij-capaciteit${systemNumber}`}
                            className='offerte__select'
                            name={`batterijCapaciteit${systemNumber}`}
                            value={totalOrder[`batterijCapaciteit${systemNumber}`]?.key || ""}
                            onChange={handleSelect}
                        >
                            <option value="" disabled>Selecteer een batterijcapaciteit</option>
                            {batterijCapList.map((cap) => (
                                <option key={cap.value} value={cap.value}>
                                    {cap.text}
                                </option>
                            ))}
                        </select>
                    </td>
                    <td className='offerte__table__cell'>&nbsp;</td>
                    <td className='offerte__table__cell'></td>
                    <td className='offerte__table__cell'></td>
                </tr>
                <tr key={`offerte__batterij-${systemNumber}`} className='offerte__table__row'>
                    <td className='offerte__table__cell offerte__table__cell-align-right'>
                        {selectedOmvormer ? selectedOmvormer.batterij : 'LFP Batterij-5,12 kWH'}
                    </td>
                    <td className='offerte__table__cell'><i>{calculateBatteries(systemNumber)} stuk(s)</i></td>
                    <td className='offerte__table__cell'></td>
                    <td className='offerte__table__cell'></td>
                </tr>
                <tr key={`offerte__bcu-${systemNumber}`} className='offerte__table__row'>
                    <td className='offerte__table__cell offerte__table__cell-align-right'>BCU</td>
                    <td className='offerte__table__cell'><i>{calculateBCUs(systemNumber)} stuk(s)</i></td>
                    <td className='offerte__table__cell'></td>
                    <td className='offerte__table__cell'></td>
                </tr>
            </>)}
        </>);
    }

    return (<>
        <SystemRow key={1} systemNumber={1} />
        {[2, 3].map(num => (
            <React.Fragment key={num}>
                <tr key={`offerte__toggle-${num}`} data-html2canvas-ignore='true' className='offerte__table__row'>
                    <td  className='offerte__table__cell' colSpan={4}>
                        <button onClick={() => toggleVisibility(num)} className="offerte__button offerte__button--small">
                            {visibleSystems[num] ? `Verberg Systeem ${num}` : `Toon Systeem ${num}`}
                        </button>
                    </td>
                </tr>
                {visibleSystems[num] && <SystemRow key={num} systemNumber={num} />}
            </React.Fragment>
        ))}
    </>);
}

export default SysteemRijen